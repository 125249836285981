import React, { useState, useEffect } from 'react';
import { initializeApp, getApp, getApps } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, deleteUser, fetchSignInMethodsForEmail, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { Button, Image, Input, Modal, notification, Radio } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './DeleteAccount.css';
import { logPageVisit } from '../../actions/AnalyticsActions';
import { useDispatch } from 'react-redux';
import { logDeletedAccount } from '../../actions/GeneralActions';

import { DeleteFilled } from "@ant-design/icons";

const DeleteAccount = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { productName } = useParams();  // Get the product name from the URL
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [specialAuth, setSpecialAuth] = useState(null);
    const [user, setUser] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [productLogo, setProductLogo] = useState(null);


    const [selectedReason, setSelectedReason] = useState("");
    const [otherReason, setOtherReason] = useState("");

    const reasons = [
        "Privacy concerns",
        "Too many notifications",
        "Not satisfied with the service",
        "I found an alternative",
        "App is difficult to use",
        "Duplicate Account",
        "Personal reasons",
        "Other",
    ];

    // Firebase configurations based on the product name
    const firebaseConfigs = {
        rapost: {
            apiKey: process.env.REACT_APP_RAPOST_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_RAPOST_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_RAPOST_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_RAPOST_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_RAPOST_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_RAPOST_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_RAPOST_FIREBASE_MEASUREMENT_ID
        }
        // Add configurations for other products
    };


    // Product logos based on the product name
    const productLogos = {
        rapost: require('../../assets/rapost.png'),
        // Add paths to logos for other products
    };

    useEffect(() => {
        if (productName) {
            dispatch(logPageVisit(`Delete Account: ${productName}`));

        }
    }, [dispatch, productName]);

    useEffect(() => {
        const config = firebaseConfigs[productName];

        if (config) {
            const initializeFirebase = async () => {
                try {
                    let app;
                    if (getApps().some(app => app.name === productName)) {
                        app = getApp(productName); // Use existing app instance
                    } else {
                        app = initializeApp(config, productName); // Create new app instance
                    }

                    // Initialize specialAuth
                    const specialAuth = getAuth(app);
                    setSpecialAuth(specialAuth);

                    // Set session-based authentication (non-persistent)
                    await setPersistence(specialAuth, browserSessionPersistence);

                    // Set product logo
                    setProductLogo(productLogos[productName]);
                } catch (error) {
                    notification.error({ message: "Error", description: error.message });
                }
            };

            initializeFirebase();
        } else {
            notification.error({ message: "Error", description: "App not found. Please check the link or contact support." });
            navigateToNotFound();
        }
    }, [productName]);


    const navigateToNotFound = () => {

        navigate(`/delete-account/${productName}/not-found`);
    }

    const handleLogin = async () => {
        if (specialAuth) {
            try {
                const userCredential = await signInWithEmailAndPassword(specialAuth, email, password);
                setUser(userCredential.user);
                setIsModalVisible(true);
            } catch (error) {
                notification.error({ message: 'Login Failed', description: error.message });
            }
        }
    };

    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({ prompt: 'select_account' });

        try {
            const result = await signInWithPopup(specialAuth, provider);

            // Get current time
            const currentTime = new Date().getTime();

            // Check if the user is new based on `metaData`
            const metadata = user?.metadata;
            const createdAt = metadata?.createdAt ? parseInt(metadata?.createdAt, 10) : 0;
            const isNewUser = (createdAt > currentTime) ? true : (currentTime - createdAt) <= 5 * 60 * 1000; // 5 minutes in milliseconds


            if (isNewUser) {
                await result?.user?.delete(); // Delete the temporary auth user

                notification.warning({
                    message: 'Login Info',
                    description: 'This google account has never been used to sign in with Rapost before. Please use the app to register this account first.'
                });
            } else {
                setUser(result.user);
                setIsModalVisible(true);

            }
        } catch (error) {
            switch (error.code) {
                case 'auth/account-exists-with-different-credential':
                    notification.error({
                        message: 'Sign-In Error',
                        description: 'This account is linked to a different sign-in method. Please use the app to connect this account first.',
                    });
                    break;

                case 'auth/user-disabled':
                    notification.error({
                        message: 'Account Disabled',
                        description: 'This account has been disabled. Please contact support for further assistance.',
                    });
                    break;

                case 'auth/user-not-found':
                    notification.error({
                        message: 'User Not Found',
                        description: 'No account found with these credentials. Please register or try signing in with a different account.',
                    });
                    break;
                case 'auth/invalid-credential':
                case 'auth/wrong-password':
                    notification.error({
                        message: 'Incorrect Password',
                        description: 'The password you entered is incorrect. Please try again or reset your password.',
                    });
                    break;

                case 'auth/popup-closed-by-user':
                    notification.warning({
                        message: 'Sign-In Cancelled',
                        description: 'The sign-in process was cancelled. Please try again.',
                    });
                    break;

                case 'auth/network-request-failed':
                    notification.error({
                        message: 'Network Error',
                        description: 'A network error occurred. Please check your connection and try again.',
                    });
                    break;

                case 'auth/too-many-requests':
                    notification.error({
                        message: 'Too Many Attempts',
                        description: 'You have made too many unsuccessful sign-in attempts. Please try again later.',
                    });
                    break;

                default:
                    notification.error({
                        message: 'Sign-In Failed',
                        description: `An unexpected error occurred: ${error.message}. Please try again.`,
                    });
                    break;
            }

        }
    };



    const handleDeleteAccount = async () => {
        const reasonForDelete = selectedReason === "Other" ? otherReason : selectedReason;
        if (user) {
            try {
                await deleteUser(user);
                notification.success({ message: 'Account Deleted Successfully' });
                dispatch(logDeletedAccount({
                    uid: user?.uid || '',
                    displayName: user?.displayName || '',
                    email: user?.email || '',
                    reasonForDelete,
                    productName
                }));
                handleCancel();

            } catch (error) {
                notification.error({ message: 'Delete Account Failed', description: error.message });
            }
        }


    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setUser(null);
        setSelectedReason("");
        setOtherReason("");
    };

    return (
        <div className="delete-account-container">
            <h2 style={{ fontSize: '1.375rem', color: '#333' }}>Delete Account for <span style={{ textTransform: 'uppercase' }}>{productName}</span></h2>
            {productLogo && <Image src={productLogo} alt={`${productName} Logo`} className="product-logo" preview={false} />}
            <Input
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input-field"
            />
            <Input.Password
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input-field"
            />
            <Button type="primary" onClick={handleLogin} className="login-button">Login</Button>
            <Button type="default" onClick={handleGoogleLogin} className="google-login-button">Login with Google</Button>

            <Modal
                title="Delete Account"
                open={isModalVisible}
                onOk={handleDeleteAccount}
                onCancel={handleCancel}
                okText={
                    <span>
                        <DeleteFilled style={{ marginRight: 8, color: 'black' }} />
                        Delete Account
                    </span>
                } cancelText="Cancel"
            >
                <p>{user?.displayName + ', ' || ''}Are you sure you want to delete your account? This action cannot be undone.</p>

                <p>Please, tell us why you want to delete (if any reason)</p>

                <Radio.Group
                    onChange={(e) => setSelectedReason(e.target.value)}
                    value={selectedReason}
                >
                    {reasons.map((reason) => (
                        <div key={reason}>
                            <Radio value={reason}>
                                {reason}
                            </Radio>
                        </div>
                    ))}
                </Radio.Group>

                {selectedReason === "Other" && (
                    <Input.TextArea
                        rows={3}
                        placeholder="Please specify your reason"
                        value={otherReason}
                        onChange={(e) => setOtherReason(e.target.value)}
                        style={{ marginTop: 10 }}
                    />
                )}
            </Modal>
        </div>
    );
};

export default DeleteAccount;
