import { addDoc, collection } from "firebase/firestore";
import { db } from "../App";

export const logDeletedAccount = (data) => {
    return async (dispatch) => {
        try {
            await addDoc(collection(db, "deletedAccounts"), {
                ...data,
                time: Date.now(),
            });

            dispatch({ type: "LOG_DELETED_ACCOUNT_SUCCESS" }); // Example success action
        } catch (error) {
            dispatch({ type: "LOG_DELETED_ACCOUNT_FAILURE", payload: error.message });
        }
    };
};

