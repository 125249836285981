import React, { useEffect, useRef, useState } from 'react';
import './Home.css'; // Import CSS file for styles
// import introImage from '../../assets/logo.png'; // Replace with actual image path
import { FiArrowRight } from 'react-icons/fi'; // Import React Icons
import { Link, useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { createProcessService } from '../../actions/ServiceActions';
import ProgressiveImage from '../common/ProgressiveImage';
import { Divider, Image } from 'antd';
import { logo, rapost, ngnantranga, yatch, swilla, tanzart, sanecka, microsoft, bluehost, google, apple, hp, amazon, namecheap, school, fuel } from '../assets/assets';
import ServiceItem from '../services/ServiceItem';
import Partners from './Carousel';
import Carousel from './Carousel';
import { TiTick } from 'react-icons/ti';
import BookDemoModal from '../services/BookDemoModal';
import ServiceModal from '../common/ServiceModal';
import { logPageVisit } from '../../actions/AnalyticsActions';

const projectsData = [

    {
        id: 2,
        name: "RAPOST - Rapid Online Store",
        type: "App",
        description: "In an age where e-commerce is rapidly evolving, Tanzator recognized the need for a platform that offers more than just a marketplace—it needed to be secure, seamless, and built on trust. Thus, RAPOST was envisioned as a revolutionary e-commerce solution where individuals could buy, sell, and exchange new, used, and refurbished products with confidence. The challenge was not just creating an online store but designing an ecosystem that guarantees quality, safety, and reliability.\n\nWith a team dedicated to innovation, Tanzator developed RAPOST as a multi-faceted platform, featuring user-friendly mobile applications, a dynamic landing page, and a powerful admin portal for streamlined management. The mobile app brings the marketplace to users’ fingertips, allowing seamless browsing, secure transactions, and real-time communication. The web landing page serves as an entry point, introducing RAPOST's unique value proposition and guiding users through its features. Behind the scenes, the admin portal ensures vendor verification, product authenticity, and transaction security.\n\nRAPOST redefines the online shopping experience by implementing a stringent vetting process for sellers, ensuring that every product meets stated specifications before it reaches the buyer. By incorporating safe transportation services, the platform guarantees that purchases arrive as expected, minimizing the risks associated with traditional online shopping. With Tanzator driving innovation, RAPOST continues to evolve, promising a future where trust and technology merge to create the ultimate e-commerce experience.",
        technologies: ["React", "Firebase"],
        status: "In Progress",
        startDate: "February 2024",
        endDate: "June 2024",
        imageUrl: "rapost",
        // imageUrl: [rapost1, rapost2],
        previewLinks: {
            website: "https://www.rapost.store",
            appStore: "https://apps.apple.com/rapost",
            playStore: "https://play.google.com/store/apps/details?id=store.rapost.app"
        }
    },
    {
        id: 5,
        name: "Sanecka Brand",
        type: "Web Development",
        description: "Sanecka Brand approached us with a vision to revolutionize the way artists connect with opportunities. They wanted a modern recruitment platform that would enable artists to showcase their talents, get discovered, and access career-changing opportunities in a seamless and efficient way.\n\nTo bring this vision to life, we developed a cutting-edge landing website that serves as the gateway for artists, recruiters, and industry professionals. The website is designed with a sleek, modern interface, ensuring effortless navigation and a visually engaging experience. It highlights Sanecka Brand’s mission while providing essential tools for artists to showcase their talents.\n\nBeyond the public-facing platform, Sanecka Brand required a powerful suite of technology-driven features to support their operations. To meet this demand, we built a feature-rich admin portal that enables their team to efficiently manage artist applications, recruitment processes, and content updates. The admin panel includes real-time analytics, custom filtering tools, automated matching algorithms, and seamless communication tools to connect artists with recruiters.\n\nAdditionally, we integrated advanced technology features such as AI-powered talent recommendations, allowing recruiters to find the best matches based on skill sets, experience, and artistic style.  The system also includes artists' special profile that can be shared to promote the artists.\n\nThrough a blend of modern design, automation, and intelligent matchmaking, Sanecka Brand now has a fully scalable digital platform that empowers artists and streamlines recruitment, helping them build successful careers with the right opportunities at their fingertips.",
        technologies: ["React", "Next.js", "Firebase", "Node.js"],
        status: "Completed",
        startDate: "January 2025",
        endDate: "February 2025",
        previewLinks: {
            website: "https://www.saneckabrand.com",
            adminPortal: "private"
        },
        isLinkReady: {
            website: true,
            adminPortal: false
        }
    },
    // {
    //     id: 4,
    //     name: "Ngnantranga",
    //     type: "Web App",
    //     description: "A website for Ngnantranga Food Hub Production; A pioneering enterprise based in Anjouan, Comoros, committed to addressing food insecurity and promoting sustainable development",
    //     technologies: ["Angular", "Java", "MySQL"],
    //     status: "Completed",
    //     startDate: "June 2024",
    //     endDate: "June 2024",
    //     imageUrl: "ngnantranga",
    //     // imageUrl: [ngnantranga1, ngnantranga2],
    //     previewLinks: {
    //         website: "https://www.ngnantranga.com",
    //         appStore: null,
    //         playStore: null
    //     }
    // }
];



const Home = () => {

    const sectionsRef = useRef([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);

    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [message, setMessage] = useState('');

    const [error, setError] = useState(false);

    const [saasDisplayed, setSaasDisplayed] = useState(0);

    const pictures = useSelector(state => state.launch.pictures);

    const [isModalVisible, setIsModalVisible] = useState(false);

     useEffect(() => {
        dispatch(logPageVisit("Home"));
      }, [dispatch]);
    

    useEffect(() => {
        const options = {
            threshold:[0.06, 0.1]
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, options);

        // Capture current value of sectionsRef.current
        const currentSections = sectionsRef.current;

        currentSections.forEach(section => {
            observer.observe(section);
        });

        return () => {
            observer.disconnect(); // Disconnect the observer

            // Clean up by unobserving each section using the captured value
            currentSections.forEach(section => {
                if (section instanceof Element && observer) {
                    observer.unobserve(section);
                }
            });
        };
    }, []); // Empty dependency array to run effect only once

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    // useEffect(() => {
    //     const handleMouseMove = (e) => {
    //         const rect = e.target.getBoundingClientRect();
    //         const x = e.clientX - rect.left; // x position within the element
    //         const y = e.clientY - rect.top;  // y position within the element

    //         e.target.style.setProperty('--mouse-x', `${x}px`);
    //         e.target.style.setProperty('--mouse-y', `${y}px`);
    //     };

    //     const element = document.querySelector('.contact-section');
    //     if (element) {
    //         element.addEventListener('mousemove', handleMouseMove);

    //         // Cleanup listener on component unmount
    //         return () => {
    //             element.removeEventListener('mousemove', handleMouseMove);
    //         };
    //     }
    // }, []); // Empty dependency array to run this effect once

    useEffect(() => {
        const handleMouseMove = (e) => {
            const rect = e.target.getBoundingClientRect();
            const x = e.clientX - rect.left; // x position within the element
            const y = e.clientY - rect.top;  // y position within the element

            e.target.style.setProperty('--mouse-x', `${x}px`);
            e.target.style.setProperty('--mouse-y', `${y}px`);
        };

        const element1 = document.querySelector('.contact-section');
        const element2 = document.querySelector('.saas');

        // Function to add event listener and return a cleanup function
        const addMouseMoveListener = (element) => {
            if (element) {
                element.addEventListener('mousemove', handleMouseMove);
                return () => {
                    element.removeEventListener('mousemove', handleMouseMove);
                };
            }
            return () => { }; // Return a no-op function if the element doesn't exist
        };

        const cleanup1 = addMouseMoveListener(element1);
        const cleanup2 = addMouseMoveListener(element2);

        // Cleanup listeners for both elements on component unmount
        return () => {
            cleanup1();
            cleanup2();
        };
    }, []); // Empty dependency array to run this effect once



    useEffect(() => {
        const container = document.querySelector('.line-container');

        // Ensure container exists
        if (!container) return;

        // Generate multiple lines dynamically
        for (let i = 0; i < 10; i++) {
            const line = document.createElement('div');
            line.className = 'line';

            // Randomize properties
            const angle = Math.random() * 90 - 45; // Random angle between -45 and 45 degrees
            const delay = Math.random() * 5; // Random delay
            const startY = Math.random() * 100; // Random vertical position

            line.style.setProperty('--angle', `${angle}deg`);
            line.style.top = `${startY}vh`;
            line.style.animationDelay = `${delay}s`;

            container.appendChild(line);
        }
    }, []);


    const saas = [
        {
            title: "Tanzator School Solutions",
            link: "https://schoolsolutions.tanzator.com",
            featuredImage: school,
            modules: [
                {
                    name: "Academic",
                    description: "",
                    features: [],
                    image: "",
                    isFeatured: false,
                    isComplete: false
                },
                {
                    name: "Finance",
                    description: "",
                    features: [],
                    image: "",
                    isFeatured: false,
                    isComplete: false
                },
                {
                    name: "Administration",
                    description: "",
                    features: [],
                    image: "",
                    isFeatured: false,
                    isComplete: false
                },
                {
                    name: "Full School System",
                    description: "",
                    features: [],
                    image: "",
                    isFeatured: true,
                    isComplete: true
                },
            ]
        },
        {
            title: "Tanzator Fuel System",
            // link: "https://fuel.tanzator.com",
            link: null,
            featuredImage: fuel,
            modules: [
                // {
                //     name: "Academic",
                //     description: "",
                // features:[],
                //     image: "",
                //     isFeatured: false,
                //     isComplete: false
                // },
                // {
                //     name: "Finance",
                //     description: "",
                // features:[],
                //     image: "",
                //     isFeatured: false,
                //     isComplete: false
                // },
                // {
                //     name: "Administration",
                //     description: "",
                // features:[],
                //     image: "",
                //     isFeatured: false,
                //     isComplete: false
                // },
                {
                    name: "Complete Fuel System",
                    description: "",
                    features: [],
                    image: "",
                    isFeatured: true,
                    isComplete: true
                },
            ]
        },

    ];
    const services1 = [
        {
            title: "Business Idea Redesign With Tech",
            image: pictures?.services?.businessIdea,
            description: "Assist in developing and refining business ideas and strategies to achieve sustainable growth and success.",
        },
        // {
        //     title: "Web and Mobile Apps Development",
        //     image: pictures?.services?.mobileApp,
        //     description: "Create innovative web solutions, and mobile applications for iOS and Android platforms, from concept to deployment.",
        // },

        {
            title: "Custom Software Development",
            image: pictures?.services?.customSoftware,
            description: "Develop bespoke software solutions to address specific business requirements and challenges.",
        },


    ];
    const services2 = [

        {
            title: "Consulting Services",
            image: pictures?.services?.consulting,
            description: "Expert consultancy to optimize business processes, improve efficiency, and achieve strategic goals.",
        },
        {
            title: "IT Solutions",
            image: pictures?.services?.itSolutions,
            description: "End-to-end IT services including infrastructure management, cloud solutions, and cybersecurity.",
        }
    ];

    const openModal = (service, action) => {
        setSelectedService(service);
        setSelectedAction(action);
        setModalOpen(true);
        setError(false);
    };

    const closeModal = () => {
        setSelectedService(null);
        setSelectedAction(null);
        setModalOpen(false);
        setError(false);
    };

    const handleSaasChange = (change) => {
        if (change === 'left') {
            if (saasDisplayed === 0) {
                setSaasDisplayed(saas?.length - 1)
            } else {
                setSaasDisplayed(saasDisplayed - 1)
            }

        } else if (change === "right") {
            if (saasDisplayed === (saas?.length - 1)) {
                setSaasDisplayed(0)
            } else {
                setSaasDisplayed(saasDisplayed + 1)
            }

        }
    };

    const handleAction = () => {
        if (name === '' || contact === '') {
            // Dispatch action for inquiry using ServiceActions
            setError(true);
            return;
        } else {
            dispatch(createProcessService(selectedAction, selectedService, name, contact, message));
            closeModal();
            setMessage('');
        }

    };

    const handleExploreEach = () => {
        navigate("/what-we-do#saas", { state: { saasPassed: saasDisplayed } });
      };


    return (
        <div className="home-container ">
            {/* <Helmet>
                <title>Tanzator - Leading ICT Company in Tanzania</title>
                <meta name="description" content="Welcome to Tanzator, your trusted partner in innovative solutions." />
                <meta name="keywords" content="Tanzator, innovative solutions, technology, Tanzania, website, software, develop, company, app, application, navigator" />
                <meta property="og:title" content="Home - Tanzator" />
                <meta property="og:description" content="Welcome to Tanzator, your trusted partner in innovative solutions." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.tanzator.com/" />
                <meta property="og:image" content="https://www.tanzator.com/logo192.png" />
                </Helmet> */}
            <Helmet>
                <title>Tanzator | Technologies and Investments</title>
                <meta name="description" content="Tanzator Investments, your trusted partner in innovative solutions, provides innovative ICT solutions including consultancy, website development and hosting, email hosting, custom software, mobile applications, and IT services." />
                <meta name="keywords" content="Tanzator, ICT services, website development, software development, mobile applications, IT solutions, digital transformation, Tanzania, consultancy, Consulting, , innovative solutions, technology, Tanzania, website, software, develop, company, app, application, navigator" />
                <meta property="og:title" content="Tanzator | Technologies and Investments" />
                <meta property="og:description" content="Discover the best ICT solutions including custom software, mobile applications, and more with Tanzator Investments." />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Tanzator Investments" />
                <meta property="og:url" content="https://www.tanzator.com" />
                <meta property="og:image" content="https://www.tanzator.com/logo192.png" />
            </Helmet>

            <section className="intro-section">
                <div className="intro-image">
                    <Image
                        src={logo}
                        // src={pictures?.company ? pictures?.company?.logo : logo}
                        alt="Intro"
                        className="logo-image"
                        preview={false}
                    />
                </div>
                <div className="intro-text">
                    <h1>Technologies and Investments</h1>
                    <p>A dependable solution, a collaborative team & delighted customers!</p>
                    {/* <button className="explore-button">Explore More</button> */}
                    <Link to="/about" className="explore-button">Who We Are</Link>
                    {/* <Link to="/about" className="explore-button">Explore More</Link> */}
                </div>
            </section>


            <section ref={el => sectionsRef.current[0] = el} className="services-section animated-section">
                <h2>Our Services</h2>
                <div className="service-list" >
                    {services1.length !== 0 &&
                        services1?.map((service, index) =>
                            <ServiceItem
                                title={service.title}
                                description={service.description}
                                openModal={openModal}
                                image={service.image}
                                key={index}

                            />
                        )
                    }
                    {/* <div className=' empty-home-service tanzator-service-item-container'>

                    </div> */}
                    {/* <section className="saas">
                        <div className='saas-slideshow'>
                        </div>
                    </section>
                    {services2.length !== 0 &&
                        services2?.map((service, index) =>
                            <ServiceItem
                                title={service.title}
                                description={service.description}
                                openModal={openModal}
                                image={service.image}
                                key={index}

                            />
                        )
                    } */}
                    {/* <div className="service-item">
                        <h3>Business Idea Redesign With Tech</h3>
                        <p>Assist in developing and refining business ideas and strategies to achieve sustainable growth and success.</p>
                        <div className="service-actions">
                            <button className="action-button inquire" onClick={() => openModal({ title: "Business Idea Redesign With Tech" }, "Inquire")}>
                                Inquire
                            </button>
                            <button className="action-button work-now" onClick={() => openModal({ title: "Business Idea Redesign With Tech" }, "WorkNow")}>
                                Let's Work
                            </button>
                        </div>
                    </div>
                    <div className="service-item">
                        <h3>Web Development</h3>
                        <p>Custom web solutions tailored to your business needs. From simple websites to complex web applications.</p>
                        <div className="service-actions">
                            <button className="action-button inquire" onClick={() => openModal({ title: "Web Development" }, "Inquire")}>
                                Inquire
                            </button>
                            <button className="action-button work-now" onClick={() => openModal({ title: "Web Development" }, "WorkNow")}>
                                Let's Work
                            </button>
                        </div>
                    </div>
                    <div className="service-item">
                        <h3>Mobile App Development</h3>
                        <p>Create innovative mobile applications for iOS and Android platforms, from concept to deployment.</p>
                        <div className="service-actions">
                            <button className="action-button inquire" onClick={() => openModal({ title: "Mobile App Development" }, "Inquire")}>
                                Inquire
                            </button>
                            <button className="action-button work-now" onClick={() => openModal({ title: "Mobile App Development" }, "WorkNow")}>
                                Let's Work
                            </button>
                        </div>
                    </div>
                    <div className="service-item">
                        <h3>Consulting Services</h3>
                        <p>Expert consultancy to optimize business processes, improve efficiency, and achieve strategic goals.</p>
                        <div className="service-actions">
                            <button className="action-button inquire" onClick={() => openModal({ title: "Consulting Services" }, "Inquire")}>
                                Inquire
                            </button>
                            <button className="action-button work-now" onClick={() => openModal({ title: "Consulting Services" }, "WorkNow")}>
                                Let's Work
                            </button>
                        </div>
                    </div>
                    <div className="service-item">
                        <h3>Custom Software Development</h3>
                        <p>Develop bespoke software solutions to address specific business requirements and challenges.</p>
                        <div className="service-actions">
                            <button className="action-button inquire" onClick={() => openModal({ title: "Custom Software Development" }, "Inquire")}>
                                Inquire
                            </button>
                            <button className="action-button work-now" onClick={() => openModal({ title: "Custom Software Development" }, "WorkNow")}>
                                Let's Work
                            </button>
                        </div>
                    </div>
                    <div className="service-item">
                        <h3>IT Solutions</h3>
                        <p>End-to-end IT services including infrastructure management, cloud solutions, and cybersecurity.</p>
                        <div className="service-actions">
                            <button className="action-button inquire" onClick={() => openModal({ title: "IT Solutions" }, "Inquire")}>
                                Inquire
                            </button>
                            <button className="action-button work-now" onClick={() => openModal({ title: "IT Solutions" }, "WorkNow")}>
                                Let's Work
                            </button>
                        </div>
                    </div> */}
                </div>

                <div class="saas">
                    <div class="top">
                        <h3>SOFTWARE AS A SERVICE (SAAS)</h3>
                        <Divider style={{ margin: '10px 2px' }} />

                        <div className="saas-controls">
                            <button className="saas-btn saas-btn-left" onClick={() => handleSaasChange('left')}>
                                &lt;
                            </button>
                            <h4 className="saas-heading">{saas[saasDisplayed]?.title}</h4>
                            <button className="saas-btn saas-btn-right" onClick={() => handleSaasChange('right')}>
                                &gt;
                            </button>
                        </div>
                        <Divider style={{ margin: '10px 2px' }} />

                    </div>
                    <div class="middle">
                        <div class="middle-left"  >
                            <Image src={saas[saasDisplayed]?.featuredImage} alt="SaaS Preview" />
                        </div>
                        <div class="middle-right">

                            <ul>
                                <div>
                                    <h3 class="saas-big-text">MODULES</h3>
                                </div>
                                <Divider />

                                {saas[saasDisplayed]?.modules?.map((module) => (
                                    <li className='saas-small-text'><TiTick color='#007bff' />{module?.name}</li>
                                ))}

                                <Divider />
                                <div>
                                    {/* <Link to={{ pathname: "/what-we-do", hash: "#saas", state: { saasPassed: saasDisplayed, nav:'asgbxjh' } }}> */}
                                        <span onClick={handleExploreEach} className="view-project-button saas-big-text" style={{ margin: 0 }}>Explore Each</span>
                                    {/* </Link> */}
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div class="bottom">
                        <button className="explore-button" onClick={() => setIsModalVisible(true)} style={{ margin: 0 }}>Book a demo </button>
                        {
                            saas[saasDisplayed]?.link &&
                            <a href={saas[saasDisplayed]?.link} target="_blank" rel="noreferrer" className="view-all-projects" style={{ textDecoration: 'none', margin: 0 }}>Visit Site</a>
                        }
                    </div>
                </div>


                <div className="service-list" style={{}} >
                    <div className=' empty-home-service tanzator-service-item-container'>

                    </div>
                    {services2.length !== 0 &&
                        services2?.map((service, index) =>
                            <ServiceItem
                                title={service.title}
                                description={service.description}
                                openModal={openModal}
                                image={service.image}
                                key={index}
                            />
                        )
                    }
                    {/* <div className="service-item">
                        <h3>Business Idea Redesign With Tech</h3>
                        <p>Assist in developing and refining business ideas and strategies to achieve sustainable growth and success.</p>
                        <div className="service-actions">
                            <button className="action-button inquire" onClick={() => openModal({ title: "Business Idea Redesign With Tech" }, "Inquire")}>
                                Inquire
                            </button>
                            <button className="action-button work-now" onClick={() => openModal({ title: "Business Idea Redesign With Tech" }, "WorkNow")}>
                                Let's Work
                            </button>
                        </div>
                    </div>
                    <div className="service-item">
                        <h3>Web Development</h3>
                        <p>Custom web solutions tailored to your business needs. From simple websites to complex web applications.</p>
                        <div className="service-actions">
                            <button className="action-button inquire" onClick={() => openModal({ title: "Web Development" }, "Inquire")}>
                                Inquire
                            </button>
                            <button className="action-button work-now" onClick={() => openModal({ title: "Web Development" }, "WorkNow")}>
                                Let's Work
                            </button>
                        </div>
                    </div>
                    <div className="service-item">
                        <h3>Mobile App Development</h3>
                        <p>Create innovative mobile applications for iOS and Android platforms, from concept to deployment.</p>
                        <div className="service-actions">
                            <button className="action-button inquire" onClick={() => openModal({ title: "Mobile App Development" }, "Inquire")}>
                                Inquire
                            </button>
                            <button className="action-button work-now" onClick={() => openModal({ title: "Mobile App Development" }, "WorkNow")}>
                                Let's Work
                            </button>
                        </div>
                    </div>
                    <div className="service-item">
                        <h3>Consulting Services</h3>
                        <p>Expert consultancy to optimize business processes, improve efficiency, and achieve strategic goals.</p>
                        <div className="service-actions">
                            <button className="action-button inquire" onClick={() => openModal({ title: "Consulting Services" }, "Inquire")}>
                                Inquire
                            </button>
                            <button className="action-button work-now" onClick={() => openModal({ title: "Consulting Services" }, "WorkNow")}>
                                Let's Work
                            </button>
                        </div>
                    </div>
                    <div className="service-item">
                        <h3>Custom Software Development</h3>
                        <p>Develop bespoke software solutions to address specific business requirements and challenges.</p>
                        <div className="service-actions">
                            <button className="action-button inquire" onClick={() => openModal({ title: "Custom Software Development" }, "Inquire")}>
                                Inquire
                            </button>
                            <button className="action-button work-now" onClick={() => openModal({ title: "Custom Software Development" }, "WorkNow")}>
                                Let's Work
                            </button>
                        </div>
                    </div>
                    <div className="service-item">
                        <h3>IT Solutions</h3>
                        <p>End-to-end IT services including infrastructure management, cloud solutions, and cybersecurity.</p>
                        <div className="service-actions">
                            <button className="action-button inquire" onClick={() => openModal({ title: "IT Solutions" }, "Inquire")}>
                                Inquire
                            </button>
                            <button className="action-button work-now" onClick={() => openModal({ title: "IT Solutions" }, "WorkNow")}>
                                Let's Work
                            </button>
                        </div>
                    </div> */}
                </div>
                <Link to="/what-we-do" className="view-all-projects">View All Services <FiArrowRight className="arrow-icon" /></Link>
            </section>

            <section
                ref={el => sectionsRef.current[1] = el}
                className="partner-section animated-section">
                <h2>Our Partners</h2>
                <Divider style={{ margin: '10px 2px' }} />

                <Carousel partnerImages={[hp, microsoft, namecheap, bluehost, google, apple, amazon]} />
                <Divider style={{ margin: '10px 2px' }} />

            </section>


            <section ref={el => sectionsRef.current[2] = el} className="projects-section animated-section">
                <h2>What we've done already</h2>
                <div className="projects-grid">
                    {projectsData.slice(0, 2).map(project => (
                        <div className="project-card" key={project.id}>
                            <ProgressiveImage
                                src={pictures?.projects?.[project.id]?.[0]}
                                alt={project.name}
                                className="home-project-image"
                            />
                            {/* <img src={project.imageUrl[0]} alt={project.name} className="home-project-image" /> */}
                            <div className="project-info">
                                <h3>{project.name}</h3>
                                {/* <p>{project.description}</p> */}
                                {/* <button className="view-project-button">View Project</button> */}
                                <Link to={`/projects/${project.id}`} className="view-project-button">View Project</Link>
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className="view-all-projects">View All Projects <FiArrowRight className="arrow-icon" /></div> */}
                <Link to="/projects" className="view-all-projects">View All Projects <FiArrowRight className="arrow-icon" /></Link>
            </section>



            <section
                ref={el => sectionsRef.current[3] = el}
                className="partner-section animated-section">
                <h2>Our Clients</h2>
                <Divider style={{ margin: '10px 2px' }} />

                <Carousel partnerImages={[rapost, ngnantranga, yatch, swilla, tanzart, sanecka]} />
                <Divider style={{ margin: '10px 2px' }} />

            </section>




            <section ref={el => sectionsRef.current[4] = el} className="contact-section animated-section">
                <h2>Get in touch</h2>
                <div className="contact-info">
                    <p>Dar es Salaam, Tanzania</p>
                    <p>Email: <a href="mailto:info@tanzator.com">info@tanzator.com</a></p>
                    <p>Phone: <a href="tel:+255765009910">+255 765 009 910</a></p>
                </div>
                <div className="contact-icons">
                    <a href="mailto:info@tanzator.com"><i className="far fa-envelope"></i></a>
                    <a href="tel:+255765009910"><i className="fas fa-phone-alt"></i></a>
                    {/* Add more icons as needed */}
                </div>
                <Link to="/contact"><FiArrowRight className="contact-arrow-icon" /></Link>
            </section>

            {modalOpen && selectedService && (
                <ServiceModal
                    modalOpen={modalOpen}
                    closeModal={closeModal}
                    selectedAction={selectedAction}
                    selectedService={selectedService}
                    buttonPosition={"Home"}
                />
                // <div className="modal">
                //     <div className="modal-content">
                //         <span className="close" onClick={closeModal}>&times;</span>
                //         <h2>{selectedService.title}</h2>
                //         <p>Please provide your contact information:</p>
                //         {error && <p className="error">Name or Contact information missing</p>}
                //         <form className="contact-form">
                //             <label htmlFor="name">Name *</label>
                //             <input
                //                 type="text"
                //                 id="name"
                //                 name="name"
                //                 placeholder="Enter your name"
                //                 value={name}
                //                 onChange={(e) => setName(e.target.value)}
                //                 required
                //             />

                //             <label htmlFor="contact">Contact Info *</label>
                //             <input
                //                 type="text"
                //                 id="contact"
                //                 name="contact"
                //                 placeholder="Enter email or phone number"
                //                 value={contact}
                //                 onChange={(e) => setContact(e.target.value)}
                //                 required
                //             />

                //             <label htmlFor="message">{selectedAction === "Inquire" ? "Inquiry information (optional)" : "Work scope (optional)"}</label>
                //             <input
                //                 type="text"
                //                 id="message"
                //                 name="message"
                //                 placeholder="More details..."
                //                 value={message}
                //                 onChange={(e) => setMessage(e.target.value)}
                //             />

                //             <button type="button" className="submit-button" onClick={handleAction}>
                //                 {selectedAction === "Inquire" ? "Inquire" : "Let's Work"}
                //             </button>
                //         </form>
                //     </div>
                // </div>
            )}


            <BookDemoModal
                visible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                buttonPosition={`Home: ${saas[saasDisplayed]?.title}`}
            />

        </div>
    );
}

export default Home;
