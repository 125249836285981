import React, { useState } from "react";
import { Modal, Form, Input, DatePicker, Button, message } from "antd";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { addSaasBooking } from "../../actions/SaasActions";

const BookDemoModal = ({ visible, onClose, buttonPosition }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

 
  const handleFinish = (values) => {
    setLoading(true);

    // Extracting and formatting the date-time
    const { name, company, position, location, dateTime } = values;
    const timestamp = dayjs(dateTime).valueOf(); // Convert to timestamp in milliseconds

    // Example payload
    const payload = {
      name,
      company,
      position,
      location,
      dateTime: timestamp,
      time:Date.now(),
      source: "Tanzator",
      buttonPosition
    };

    dispatch(addSaasBooking(payload));
    // console.log("Booking Details:", payload);
    // message.success("Demo booked successfully!");

    // Reset and close form
    form.resetFields();
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      title="Book a demo"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{
          name: "",
          company: "",
          position: "",
          location: "",
        }}
      >
        {/* Name */}
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter your name!" }]}
        >
          <Input placeholder="Enter your full name" />
        </Form.Item>

        {/* Business/Company */}
        <Form.Item
          label="Business/Company"
          name="company"
          rules={[{ required: true, message: "Please enter your business/company!" }]}
        >
          <Input placeholder="Enter your business or company name" />
        </Form.Item>

        {/* Position */}
        <Form.Item
          label="Position"
          name="position"
          rules={[{ required: true, message: "Please enter your position!" }]}
        >
          <Input placeholder="Enter your position in the company" />
        </Form.Item>

        {/* Location */}
        <Form.Item
          label="Location"
          name="location"
          rules={[{ required: true, message: "Please enter your location!" }]}
        >
          <Input placeholder="Enter your location" />
        </Form.Item>

        {/* Date Time Picker */}
        <Form.Item
          label="Date and Time"
          name="dateTime"
          rules={[{ required: true, message: "Please select a date and time!" }]}
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            style={{ width: "100%" }}
          />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: "100%" }}
          >
            Book Demo
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BookDemoModal;
