import React, { useEffect, useRef, useState } from "react";
import "./Carousel.css";

const Carousel = ({ partnerImages }) => {
  const scrollContainer = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const infiniteImages = [...partnerImages];

  useEffect(() => {
    const container = scrollContainer.current;
    let scrollAmount = 0;

    const scroll = () => {
      if (container && !isHovered) {
        scrollAmount += 1; // Scroll speed
        if (scrollAmount >= container.scrollWidth - container.clientWidth) {
          scrollAmount = 0; // Reset to simulate infinite scroll
        }
        container.scrollLeft = scrollAmount;
      }
    };

    const interval = setInterval(scroll, 20); // Adjust speed
    return () => clearInterval(interval);
  }, [isHovered]);

  // Function to check scroll position and show/hide arrows
  const updateArrowsVisibility = () => {
    const container = scrollContainer.current;
    if (!container) return;

    setShowLeftArrow(container.scrollLeft > 0);
    setShowRightArrow(container.scrollLeft < container.scrollWidth - container.clientWidth);
  };

  useEffect(() => {
    updateArrowsVisibility();
    const container = scrollContainer.current;
    if (container) {
      container.addEventListener("scroll", updateArrowsVisibility);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", updateArrowsVisibility);
      }
    };
  }, []);

  const handleScrollLeft = () => {
    scrollContainer.current.scrollBy({ left: -150, behavior: "smooth" });
  };

  const handleScrollRight = () => {
    scrollContainer.current.scrollBy({ left: 150, behavior: "smooth" });
  };

  return (
    <section className="partners-section">
      <div
        className="partners-wrapper"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {showLeftArrow && (
          <button className="scroll-btn left" onClick={handleScrollLeft}>
            &lt;
          </button>
        )}

        <div className="partners-container" ref={scrollContainer}>
          {infiniteImages.map((src, index) => (
            <div key={index} className="partner-logo">
              <img src={src} alt={`Partner ${index + 1}`} />
            </div>
          ))}
        </div>

        {showRightArrow && (
          <button className="scroll-btn right" onClick={handleScrollRight}>
            &gt;
          </button>
        )}
      </div>
    </section>
  );
};

export default Carousel;
