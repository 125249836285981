import { addDoc, collection, getDocs } from "firebase/firestore";
import { UAParser } from "ua-parser-js";
import { FETCH_ANALYTICS, LOG_PAGE_VISIT } from "./Types";
import { db } from "../App";


// Function to get location (uses free geolocation API)
const getLocation = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    return `${data.city}, ${data.country_name}`;
  } catch (error) {
    return "Unknown Location";
  }
};

const getDeviceId = () => {
  let deviceId = localStorage.getItem("deviceId");

  if (!deviceId) {
    deviceId = crypto.randomUUID(); // Generate unique ID
    localStorage.setItem("deviceId", deviceId);
  }

  return deviceId;
};

const getIPAddress = async () => {
  try {
    const response = await fetch("https://api64.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Failed to fetch IP", error);
    return "Unknown IP";
  }
};


// Logs a page visit with OS, device type, location, etc.
export const logPageVisit = (pageName) => async (dispatch, getState) => {

  // Access the current state
  const state = getState();

  // Example: Access analytics data
  const currentVisits = state.analytics.currentVisits;
  const entry = state.analytics.entry;

  if (currentVisits.includes(pageName)) {
    // console.log('already laoded',pageName)
    return;
  }
 
  try {
    const parser = new UAParser();
    const browserInfo = parser.getResult();

    const deviceId = getDeviceId();
    const ipAddress = await getIPAddress();

    const visitData = {
      event: "page_view",
      page: pageName,
      timestamp: new Date(),
      time: Date.now(),
      os: browserInfo.os.name || "Unknown OS",
      device: browserInfo.device.type || "Desktop",
      browser: browserInfo.browser.name || "Unknown Browser",
      location: await getLocation(),
      source: "Tanzator",
      deviceId,
      ipAddress,
      entry
    };

    await addDoc(collection(db, "analytics"), visitData);

    dispatch({ type: LOG_PAGE_VISIT, payload: visitData });
  } catch (error) {
    console.error("Error logging page visit:", error);
  }
};

// Fetches all analytics data
export const fetchAnalyticsData = () => async (dispatch) => {
  try {
    const querySnapshot = await getDocs(collection(db, "analytics"));
    const analyticsData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    dispatch({ type: FETCH_ANALYTICS, payload: analyticsData });
  } catch (error) {
    console.error("Error fetching analytics data:", error);
  }
};
