import { BOOKING_FAILURE, BOOKING_REQUEST, BOOKING_SUCCESS, SAAS_REQUEST_FAILURE, SAAS_REQUEST_INITIATE, SAAS_REQUEST_SUCCESS } from "../actions/Types";

const initialState = {
    loading: false,
    success: false,
    error: null,
    bookings: [],
    requests: [],
};

const SaasReducer = (state = initialState, action) => {
    switch (action.type) {
        case BOOKING_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: null,
            };
        case BOOKING_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                // bookings: [...state.bookings, action.payload],
            };
        case BOOKING_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };


        case SAAS_REQUEST_INITIATE:
            return { ...state, loading: true, success: false, error: null };
        case SAAS_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                // requests: [...state.requests, action.payload],
            };
        case SAAS_REQUEST_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload };

        default:
            return state;
    }
};

export default SaasReducer;
