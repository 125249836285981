import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css'; // Import your CSS file for styling
import { logPageVisit } from '../../actions/AnalyticsActions';
import { useDispatch } from 'react-redux';

const NotFound = () => {
  const dispatch =useDispatch();

   useEffect(() => {
      dispatch(logPageVisit("Not Found"));
    }, [dispatch]);
  

  // Scroll to the top of the page when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="not-found-container">
      <h2>404 - Page Not Found</h2>
      <p>The page you are looking for does not exist.</p>
      <Link to="/" className="home-link">Go to Home</Link>
    </div>
  );
}

export default NotFound;
