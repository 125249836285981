import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaGlobe, FaApple, FaGooglePlay } from 'react-icons/fa';
import './ProjectDetails.css';
import { useDispatch, useSelector } from 'react-redux';
import ProgressiveImage from '../common/ProgressiveImage';
import { Divider, notification } from 'antd';
import AppDownloadModal from './AppDownloadModal';
import { logPageVisit } from '../../actions/AnalyticsActions';
import { Helmet } from 'react-helmet';

const projectsData = [

    {
        id: 1,
        name: "Tanzart",
        type: "Mobile App",
        description: "Tanzator embarked on a journey to revolutionize the world of digital art by crafting a platform where creativity knows no bounds. Tanzart was born out of the realization that artists across various disciplines—whether poets, musicians, storytellers, visual artists, or performers—lacked a dedicated space to share and develop their work seamlessly. The idea was ambitious: to create an all-in-one mobile application that not only enables artists to showcase their creations but also fosters a thriving community of like-minded individuals.\n\nFrom conceptualization to execution, Tanzator meticulously designed Tanzart, ensuring it offers an intuitive and immersive user experience. The mobile app, provides a smooth and dynamic interface where users can upload, edit, and share their work effortlessly. Complementing the mobile experience, a well-structured landing web page introduces users to the platform’s offerings, setting the stage for deeper engagement. Behind the scenes, an advanced admin portal made for content management, user interactions, and moderation, ensuring a safe and vibrant ecosystem.\n\nTanzart is more than just an application; it is a movement. It empowers artists to turn their passions into lifelong pursuits, bridging the gap between traditional and digital artistry. With a vision of continuous expansion, Tanzator remains committed to enhancing Tanzart with innovative features, making it the ultimate artistic hub of the future.",
        keywords: "Digital art platform, Sanaa ya kidijitali, Tanzator Technologies, Creativity hub, Kituo cha ubunifu, Artistic community, Jamii ya wasanii, Mobile app for artists, Programu ya wasanii, Showcase and share art, Onyesha na shiriki sanaa, Poems, Nyimbo, Hadithi, Picha, Michoro, Uigizaji, React Native, User-friendly interface, Kiolesura rahisi kutumia, Creative expression, Uelekezaji wa ubunifu, Online art community, Jamii ya sanaa mtandaoni, Artistic collaboration, Ushirikiano wa kisanaa, Artistic growth, Ukuaji wa kisanaa, Digital creativity, Ubunifu wa kidijitali, Content management, Usimamizi wa maudhui, Artistic hub of the future, Kituo cha kisanaa cha siku zijazo",
        technologies: ["React Native", "Node.js", "MongoDB"],
        status: "In Progress",
        // status: "Ongoing",
        startDate: "September 2023",
        endDate: "February 2025",
        previewLinks: {
            website: "https://www.tanzart.art",
            appStore: "",
            playStore: "pending"
        },
        isLinkReady: {
            website: true,
            appStore: false,
            playStore: false
        },
        downloadAPK: {
            link: "link",
            name: "Tanzart.apk"
        }
    },
    {
        id: 2,
        name: "RAPOST - Rapid Online Store",
        type: "Mobile App",
        description: "In an age where e-commerce is rapidly evolving, Tanzator recognized the need for a platform that offers more than just a marketplace—it needed to be secure, seamless, and built on trust. Thus, RAPOST was envisioned as a revolutionary e-commerce solution where individuals could buy, sell, and exchange new, used, and refurbished products with confidence. The challenge was not just creating an online store but designing an ecosystem that guarantees quality, safety, and reliability.\n\nWith a team dedicated to innovation, Tanzator developed RAPOST as a multi-faceted platform, featuring user-friendly mobile applications, a dynamic landing page, and a powerful admin portal for streamlined management. The mobile app brings the marketplace to users’ fingertips, allowing seamless browsing, secure transactions, and real-time communication. The web landing page serves as an entry point, introducing RAPOST's unique value proposition and guiding users through its features. Behind the scenes, the admin portal ensures vendor verification, product authenticity, and transaction security.\n\nRAPOST redefines the online shopping experience by implementing a stringent vetting process for sellers, ensuring that every product meets stated specifications before it reaches the buyer. By incorporating safe transportation services, the platform guarantees that purchases arrive as expected, minimizing the risks associated with traditional online shopping. With Tanzator driving innovation, RAPOST continues to evolve, promising a future where trust and technology merge to create the ultimate e-commerce experience.",
        keywords: "E-commerce platform, Jukwaa la biashara mtandaoni, Online marketplace, Soko la mtandaoni, Secure transactions, Muamala salama, Buy and sell products, Nunua na uza bidhaa, New, used, and refurbished products, Bidhaa mpya, zilizotumika, na zilizokarabatiwa, Exchange products, Badilisha bidhaa, Vetted sellers, Wauzaji waliothibitishwa, Quality assurance, Uhakikisho wa ubora, Flutter mobile app, Programu ya simu ya Flutter, Real-time communication, Mawasiliano ya moja kwa moja, Firebase scalability, Uwezo wa Firebase kupanuka, Seamless shopping experience, Uzoefu wa ununuzi usio na usumbufu, Safe transportation, Usafirishaji salama, Transaction security, Usalama wa muamala, Tanzator Technologies, Trustworthy e-commerce, Biashara mtandaoni ya kuaminika, Online shopping, Ununuzi mtandaoni, Revolutionary marketplace, Soko la mapinduzi",
        technologies: ["Flutter", "Firebase"],
        status: "Completed",
        startDate: "February 2024",
        endDate: "August 2024",
        previewLinks: {
            website: "https://www.rapost.store",
            appStore: "pending",
            playStore: "https://play.google.com/store/apps/details?id=store.rapost.app"
        },
        isLinkReady: {
            website: true,
            appStore: false,
            playStore: true
        },
        downloadAPK: {
            link: "https://expo.dev/artifacts/eas/wk9WwncTRTjFxjcjzNfPz3.apk",
            name: "Rapost.apk"
        }
    },
    {
        id: 3,
        name: "Yacht Cruise Connect",
        type: "Web App",
        description: "The vision behind Yacht Cruise Connect was clear—to create a seamless and luxurious booking platform for yacht enthusiasts and event planners alike. Before its inception, booking a yacht cruise often involved lengthy processes, multiple intermediaries, and uncertainty. Tanzator saw an opportunity to transform this experience into one of ease, efficiency, and sophistication.\n\nThrough meticulous planning and development, the Yacht Cruise Connect team collaborated with Tanzator to bring this vision to life. The result was a beautifully crafted landing page that serves as the gateway to an effortless booking experience. Designed with precision, the website captures the elegance and excitement of yacht adventures, allowing users to explore available cruises, select their preferred options, and make reservations with just a few clicks.\n\nBeyond the front-facing experience, an advanced admin portal was built to manage reservations, customer inquiries, and special event planning. With an intuitive dashboard, yacht service providers can oversee bookings, customize offerings, and provide real-time availability updates.\n\nYacht Cruise Connect is more than just a booking site; it is a bridge between adventure seekers and premium yacht experiences. Thanks to Tanzator’s innovative approach, the platform continues to redefine how people plan and enjoy their time on the water.",
        keywords: "Yacht booking platform, Jukwaa la kukodisha yacht, Luxurious yacht cruises, Safari za yacht za kifahari, Online reservation, Uhifadhi mtandaoni, Seamless booking experience, Uzoefu wa uhifadhi usio na usumbufu, Event planning, Mipango ya matukio, Yacht adventures, Vituko vya yacht, Tanzator Technologies, Premium yacht services, Huduma za yacht za kiwango cha juu, Real-time availability, Upatikanaji wa papo hapo, React frontend, Sehemu ya mbele ya React, Node.js backend, Sehemu ya nyuma ya Node.js, PostgreSQL data management, Usimamizi wa data wa PostgreSQL, Elegant web design, Ubunifu wa tovuti wa kifahari, Customizable yacht experiences, Uzoefu wa yacht unaoweza kubinafsishwa, Yacht booking made easy, Kukodisha yacht kwa urahisi, Adventure seekers, Wapenda vituko, Luxury travel, Safari za kifahari",
        technologies: ["React", "Node.js", "PostgreSQL"],
        status: "Completed",
        startDate: "June 2024",
        endDate: "July 2024",
        previewLinks: {
            website: "https://www.yatchcruiseconnect.com",
            appStore: null,
            playStore: null
        }
    },
    {
        id: 4,
        name: "Ngnantranga",
        type: "Web App",
        description: "In the heart of Anjouan, Comoros, the need for sustainable food production and security became more pressing than ever. Ngnantranga Food Hub Production, a pioneering enterprise, aimed to address these challenges by providing innovative solutions for local agriculture, food distribution, and sustainability. However, to amplify its impact and reach a wider audience, Ngnantranga needed a digital presence that reflected its mission and values.\n\nTanzator stepped in to transform this vision into reality. With a deep understanding of Ngnantranga’s goals, the team designed a compelling landing page that tells the story of resilience, sustainability, and progress. The website showcases the enterprise’s initiatives, educates visitors on food security, and provides a seamless experience for stakeholders to engage with Ngnantranga’s work.\n\nIn addition to the public-facing platform, an admin portal was developed to streamline internal operations. Through this secure portal, the Ngnantranga team can manage content, track engagement, and maintain an active connection with the community.\n\nBy blending technology with purpose, Tanzator has enabled Ngnantranga to extend its reach, drive its mission forward, and contribute to a future where food security is no longer a challenge but a reality for communities in need.",
        keywords: "Food hub production, Kituo cha uzalishaji wa chakula, Sustainable agriculture, Kilimo endelevu, Food security, Usalama wa chakula, Local agriculture solutions, Suluhisho za kilimo za ndani, Community empowerment, Uwezeshaji wa jamii, Digital presence, Uwepo wa kidijitali, Tanzator Technologies, Resilience and sustainability, Ustahimilivu na uendelevu, Engaging landing page, Ukurasa wa kutua unaovutia, Community engagement, Ushirikiano wa jamii, Internal operations management, Usimamizi wa shughuli za ndani, Angular frontend, Sehemu ya mbele ya Angular, Java backend, Sehemu ya nyuma ya Java, MySQL data management, Usimamizi wa data wa MySQL, Food distribution, Usambazaji wa chakula, Social impact, Athari za kijamii, Agricultural innovation, Ubunifu wa kilimo, Food security solutions, Suluhisho za usalama wa chakula, Digital storytelling, Usimulizi wa hadithi za kidijitali",
        technologies: ["Angular", "Java", "MySQL"],
        status: "Completed",
        startDate: "June 2024",
        endDate: "June 2024",
        previewLinks: {
            website: "https://www.ngnantranga.com",
            appStore: null,
            playStore: null
        }
    },
    {
        id: 5,
        name: "Sanecka Brand",
        type: "Web Development",
        description: "Sanecka Brand approached us with a vision to revolutionize the way artists connect with opportunities. They wanted a modern recruitment platform that would enable artists to showcase their talents, get discovered, and access career-changing opportunities in a seamless and efficient way.\n\nTo bring this vision to life, we developed a cutting-edge landing website that serves as the gateway for artists, recruiters, and industry professionals. The website is designed with a sleek, modern interface, ensuring effortless navigation and a visually engaging experience. It highlights Sanecka Brand’s mission while providing essential tools for artists to showcase their talents.\n\nBeyond the public-facing platform, Sanecka Brand required a powerful suite of technology-driven features to support their operations. To meet this demand, we built a feature-rich admin portal that enables their team to efficiently manage artist applications, recruitment processes, and content updates. The admin panel includes real-time analytics, custom filtering tools, automated matching algorithms, and seamless communication tools to connect artists with recruiters.\n\nAdditionally, we integrated advanced technology features such as AI-powered talent recommendations, allowing recruiters to find the best matches based on skill sets, experience, and artistic style.  The system also includes artists' special profile that can be shared to promote the artists.\n\nThrough a blend of modern design, automation, and intelligent matchmaking, Sanecka Brand now has a fully scalable digital platform that empowers artists and streamlines recruitment, helping them build successful careers with the right opportunities at their fingertips.",
        keywords: "Artistic recruitment platform, modern recruitment for artists, talent discovery and career opportunities, artist profile promotion, AI-powered talent recommendations, intelligent matchmaking for artists, digital platform for creative careers, recruitment automation for creative industries, artist application management, Sanecka Brand recruitment solutions, artist networking and exposure, online platform for artistic talents, seamless artist-recruiter communication, scalable digital platform for creatives, teknolojia ya usajili wa wasanii, jukwaa la kuonyesha vipaji vya sanaa, fursa za kazi kwa wasanii, usajili wa kisasa kwa wasanii, mapendekezo ya vipaji kwa kutumia AI, mawasiliano kati ya wasanii na waajiri, wasanii kupata nafasi za kazi mtandaoni, mfumo wa kutafuta vipaji kwa akili ya bandia, majukwaa ya kisasa kwa wasanii, ufumbuzi wa Sanecka Brand kwa usajili wa wasanii, urahisi wa kuunganisha wasanii na waajiri, nafasi za kujitangaza kwa wasanii mtandaoni",
        technologies: ["React", "Next.js", "Firebase", "Node.js"],
        status: "Completed",
        startDate: "January 2025",
        endDate: "February 2025",
        previewLinks: {
            website: "https://www.saneckabrand.com",
            adminPortal: "private"
        },
        isLinkReady: {
            website: true,
            adminPortal: false
        }
    },
    {
        id: 6,
        name: "Tanzator School Solutions",
        type: "Web & Mobile App",
        description: "Tanzator School Solutions is a transformative suite designed to modernize and streamline school management and student learning. Traditionally, many educational institutions faced challenges in handling academic records, financial management, administration, and student engagement due to reliance on manual processes. Tanzator School Solutions was created to bridge this gap by providing a fully integrated digital platform tailored to the needs of schools, teachers, parents, and students.\n\nThe system introduces a comprehensive range of features, including academic management, financial tracking, administrative automation, and interactive student learning tools. Schools can efficiently manage enrollment, attendance, grading, and communication while ensuring transparency in budgeting, fee collection, and resource allocation. Additionally, educators can enhance student engagement through digital learning resources, online assessments, and performance tracking.\n\nBeyond its current capabilities, Tanzator School Solutions is continuously evolving, with a vision to explore and integrate emerging technologies to further enhance the education sector. From AI-driven analytics to adaptive learning environments, Tanzator is committed to shaping the future of education through innovation, accessibility, and efficiency.",
        keywords: "Digital school management platform, educational administration automation, student learning tools, academic and financial management for schools, integrated school system, digital education solutions, AI-driven analytics in education, online assessments and grading, performance tracking for students, interactive learning resources, modern school management software, Tanzator School Solutions, student engagement tools, adaptive learning environments, teknolojia ya usimamizi wa shule, mfumo wa kisasa wa shule, usimamizi wa fedha na masomo kwa shule, zana za kujifunza kwa wanafunzi, suluhisho za kidijitali kwa elimu, mfumo wa usajili na mahudhurio, usimamizi wa alama na mitihani mtandaoni, rasilimali za kujifunza mtandaoni, ufumbuzi wa Tanzator School Solutions, elimu ya kidijitali na teknolojia ya AI, jukwaa la kisasa la elimu, mfumo wa kujifunza kwa njia ya kipekee, ufuatiliaji wa maendeleo ya wanafunzi",
        technologies: ["React", "React Native", "Node.js", "Firebase"],
        status: "Completed",
        startDate: "November 2024",
        endDate: "December 2024",
        previewLinks: {
            "website": "https://schoolsolutions.tanzator.com",
            "appStore": null,
            "playStore": null
        },
        isLinkReady: {
            website: true,
            adminPortal: false
        }
    }

];


const handleAppStoreClick = () => {
    notification.info({
        message: 'Coming Soon!',
        description: 'The app will be available in the App Store soon. Stay tuned!',
        placement: 'topRight',
        duration: 5,
    });
};
const handlePlayStoreClick = () => {
    notification.info({
        message: 'Coming Soon!',
        description: 'The app will be available in the Google Play Store soon. Stay tuned!',
        placement: 'topRight',
        duration: 5,
    });
};



const ProjectDetails = () => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const project = projectsData.find(proj => proj.id === parseInt(projectId));
    const pictures = useSelector(state => state.launch.pictures);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [downloadAPK, setDownloadAPK] = useState({});
    const [showInstructions, setShowInstructions] = useState(false);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        if (project) {
            dispatch(logPageVisit(`Project Details: ${project?.name || 'Unknown'}`));

        }
    }, [dispatch, project]);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!project) return <div>Project not found.</div>;

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === pictures?.projects?.[projectId]?.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? pictures?.projects?.[projectId]?.length - 1 : prevIndex - 1
        );
    };

    const handleDownloadAPK = (downloadAPK) => {
        setDownloadAPK(downloadAPK);
        setShowInstructions(false);
        setIsModalVisible(true);
    };

    return (
        <div className="project-details-container">
            <Helmet>
                {project.name && <title>{project.name} | Tanzator</title>}
                {project.description && <meta name="description" content={project.description} />}
                {project.keywords && <meta name="keywords" content={project.keywords} />}
                {project.name && <meta property="og:title" content={project.name} />}
                {project.description && <meta property="og:description" content={project.description} />}
                {pictures?.projects?.[projectId]?.[currentImageIndex] && <meta property="og:image" content={pictures?.projects?.[projectId]?.[currentImageIndex]} />}
                {project.previewLinks?.website && <meta property="og:url" content={project.previewLinks.website} />}

                <meta name="twitter:card" content="summary_large_image" />
                {project.name && <meta name="twitter:title" content={project.name} />}
                {project.description && <meta name="twitter:description" content={project.description} />}
                {pictures?.projects?.[projectId]?.[currentImageIndex] && <meta name="twitter:image" content={pictures?.projects?.[projectId]?.[currentImageIndex]} />}

                {project.previewLinks?.website && <link rel="canonical" href={project.previewLinks.website} />}
                {project.previewLinks?.appStore && <link rel="alternate" href={project.previewLinks.appStore} hrefLang="en" />}
                {project.previewLinks?.playStore && <link rel="alternate" href={project.previewLinks.playStore} hrefLang="en" />}
            </Helmet>
            <div className="project-details-header">
                <div className="project-details-content">
                    <h1 className="project-details-title">{project.name}</h1>
                    <p className="project-details-type">{project.type}</p>
                    <div className="project-details-section">
                        {/* <h2 className="section-title">Project Duration</h2> */}
                        <p className="project-details-section-content duration">{project.startDate} - {project.endDate}</p>
                    </div>
                    <div className="project-details-section">
                        {/* <h2 className="section-title">Project Status</h2> */}
                        <p className="project-details-section-content status">{project.status}</p>
                    </div>
                </div>
                <div className="project-details-carousel">
                    {
                        currentImageIndex !== 0 &&
                        <button className="carousel-button prev-button" onClick={prevImage}>&#10094;</button>
                    }
                    {/* <Link
                        to={{
                            pathname: `/picture/${project.name}`
                        }}
                        state={{ currentImageIndex, projectId }} // Pass additional props in the state object
                    > */}
                    <>
                        <ProgressiveImage
                            //  src={project.imageUrl[currentImageIndex]}
                            src={pictures?.projects?.[projectId]?.[currentImageIndex]}
                            alt={`${project.name}  ${currentImageIndex + 1}`}
                            className="project-details-image"
                        />
                    </>
                    {/* </Link> */}
                    {
                        currentImageIndex !== pictures?.projects?.[projectId]?.length - 1 &&
                        <button className="carousel-button next-button" onClick={nextImage}>&#10095;</button>
                    }
                </div>
            </div>

            <Divider style={{ margin: '10px 2px' }} />
            <div className="project-details-section">
                {/* <h2 className="section-title">Preview Links</h2> */}
                <div className="preview-links">
                    {project.previewLinks.website && (
                        <a href={project.previewLinks.website} target="_blank" rel="noopener noreferrer" className="preview-link">
                            <FaGlobe className="icon" /> Visit Website
                        </a>
                    )}

                    {project.previewLinks.appStore && project.previewLinks.appStore === 'pending' && (
                        <div onClick={handleAppStoreClick} className="preview-link" title="appStore" >
                            <FaApple className="icon" />
                            <span>
                                <span className="small-text">Download on the</span>
                                <span className="main-text">App Store</span>
                            </span>
                        </div>
                    )}
                    {project.previewLinks.appStore && project.previewLinks.appStore !== 'pending' && (
                        <a href={project.previewLinks.appStore} target="_blank" rel="noopener noreferrer" className="preview-link" title="appStore" >
                            <FaApple className="icon" />
                            <span>
                                <span className="small-text">Download on the</span>
                                <span className="main-text">App Store</span>
                            </span>
                        </a>
                    )}
                    {project.previewLinks.playStore && project.previewLinks.playStore === 'pending' && (
                        <div onClick={handlePlayStoreClick} className="preview-link" title="playStore">
                            <FaGooglePlay className="icon" />
                            <span>
                                <span className="small-text">Get it on</span>
                                <span className="main-text">Google Play</span>
                            </span>
                        </div>
                    )}

                    {project.previewLinks.playStore && !project.isLinkReady.playStore && project.previewLinks.playStore !== 'pending' && (
                        <div onClick={() => handleDownloadAPK(project?.downloadAPK)} className="preview-link" title="playStore">
                            <FaGooglePlay className="icon" />
                            <span>
                                <span className="small-text">Get it on</span>
                                <span className="main-text">Google Play</span>
                            </span>
                        </div>
                    )}

                    {project.previewLinks.playStore && project.isLinkReady.playStore && project.previewLinks.playStore !== 'pending' && (
                        <a href={project.previewLinks.playStore} target="_blank" rel="noopener noreferrer" className="preview-link" title="playStore">
                            <FaGooglePlay className="icon" />
                            <span>
                                <span className="small-text">Get it on</span>
                                <span className="main-text">Google Play</span>
                            </span>
                        </a>
                    )}

                </div>
            </div>
            <Divider style={{ margin: '10px 2px' }} />

            <div className="project-details-body">


                <div className="project-details-section">
                    {/* <h2 className="section-title">Project Description</h2> */}
                    <p className="project-details-section-content description">
                        {project?.description?.split('\n').map((line, index) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </p>
                </div>
                {/* <div className="project-details-section">
                    <h2 className="section-title">Technologies Used</h2>
                    <ul className="section-list">
                        {project.technologies.map((tech, index) => (
                            <li key={index}>{tech}</li>
                        ))}
                    </ul>
                </div> */}


            </div>
            <AppDownloadModal downloadAPK={downloadAPK} showInstructions={showInstructions} setShowInstructions={setShowInstructions} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} key={downloadAPK.link} />
        </div>
    );
}

export default ProjectDetails;
