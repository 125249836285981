import React from "react";
import { Link } from "react-router-dom";
// import ProgressiveImage from "react-progressive-image";
import "./TeamList.css";
import ProgressiveImage from "../common/ProgressiveImage";

const TeamList = ({ teamData, pictures, visibilityRef, visibleSections }) => {
    return (
        <section className="alternating-team-list">
            {teamData.map((member, index) => (
                <div
                    ref={(el) => (visibilityRef.current[index] = el)}
                    data-index={index}

                    key={member.id}
                    className={`team-member-row ${index % 2 === 0 ? "align-left" : "align-right"}  ${visibleSections[index] ? (index % 2 === 0 ? "slide-in-left" : "slide-in-right") : ""}`}
                >
                    <Link to={`/staff/${member.id}`} className="team-member-link">
                        <div className={`team-member-card ${index % 2 === 0 ? "aligned-left" : "aligned-right"}`}>
                            <div
                                className={`image-container ${visibleSections[index] ? (index % 2 === 0 ? "slide-in-left" : "slide-in-right") : ""}`}
                            >
                                <ProgressiveImage
                                    src={pictures?.staff?.[member.id]}
                                    alt={member.name}
                                    className="team-member-image"
                                />
                            </div>
                            <div
                                className={`team-member-info ${index % 2 === 0 ? "fade-in-left" : "fade-in-right"}`}
                            >
                                <h2 className="team-member-name">{member.name}</h2>
                                <p className="team-member-position">{member.position}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ))}

        </section>
    );
};

export default TeamList;
