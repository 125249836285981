import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './Staff.css';
import { matrixTextEffect } from '../../utils';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import ProgressiveImage from '../common/ProgressiveImage';
import { Collapse, Image } from 'antd';
import { FiArrowRight } from 'react-icons/fi';
import { team } from '../assets/assets';
import TeamList from './TeamList';
import { logPageVisit } from '../../actions/AnalyticsActions';

const { Panel } = Collapse;

const teamData = [
    {
        id: 1,
        name: "Navigator Moses Timothy",
        position: "Founder, CEO & Software Engineer",
        bio: "Navigator Moses Timothy is the visionary leader and founder of our company. With a passion for innovation and technology, he is driving our team towards success.",
        email: "ceo@tanzator.com",
        phone: "+255765009910",
        linkedin: "https://www.linkedin.com/in/tanzator"
    },
    {
        id: 2,
        name: "Swaghi Sonda Machafu",
        position: "General Manager",
        bio: "Swaghi Sonda Machafu, our dedicated General Manager, is renowned for his exceptional organizational skills and strategic vision. His commitment to excellence and keen oversight ensure that our projects run smoothly and successfully, driving our team towards new heights.",
        email: "manager@tanzator.com",
        phone: "+255685358479"
    }
    // Add more team members as needed
];




const services = [
    {
        name: 'Entrepreneurship',
        description: 'With a proven track record of launching and scaling multiple successful ventures across various industries, our team brings a wealth of experience in entrepreneurship. From ideation to execution, we have guided numerous businesses to sustainable growth, driving innovation and excellence at every stage.',
    },
    {
        name: 'Custom Software Development',
        description: 'Creation of bespoke software solutions, ranging from desktop applications to mobile tools, designed to meet unique business needs.',
    },
    {
        name: 'Android and iOS App Development',
        description: 'Development and deployment of native Android and iOS applications, tailored for both consumer and enterprise needs.',
    },
    {
        name: 'Web Development and SEO',
        description: 'Building responsive, user-friendly websites optimized for search engines to improve visibility and performance online.',
    },
    {
        name: 'E-Commerce Solutions and Payment Gateway Integration',
        description: 'Expertise in setting up e-commerce platforms, integrating secure payment gateways, and optimizing the online shopping experience.',
    },
    {
        name: 'API Integration',
        description: 'Seamless integration of APIs with third-party services and internal systems for smooth and secure data flow.',
    },
    {
        name: 'Enterprise Resource Planning (ERP)',
        description: 'Development and integration of ERP systems to streamline business operations, inventory, finance, and human resources.',
    },
    {
        name: 'Business Intelligence (BI) and Data Analytics',
        description: 'Providing BI tools and data analytics services to help businesses make data-driven decisions and optimize performance.',
    },
    {
        name: 'Database Management and Optimization',
        description: 'Database design, management, and optimization for high-performance and reliable data storage systems.',
    },
    // {
    //   name: 'DevOps and Continuous Integration/Continuous Deployment (CI/CD)',
    //   description: 'Expertise in DevOps practices, enabling fast, reliable, and scalable software deployment.',
    // },
    // {
    //   name: 'Blockchain Technology and Smart Contracts',
    //   description: 'Expertise in blockchain solutions, including the development of decentralized apps (DApps) and smart contracts for secure transactions.',
    // },
    // {
    //   name: 'Telecom Solutions and VoIP Systems',
    //   description: 'Implementation of telecom solutions, including VoIP (Voice over IP) systems, to enable efficient communication networks.',
    // },
    {
        name: 'IT Consulting and Strategy Development',
        description: 'Offering expert IT consulting services, including strategy development, system audits, and technology roadmap creation.',
    },
    {
        name: 'Ethical Hacking',
        description: 'Expert ethical hacking services to identify vulnerabilities in systems, applications, and networks to improve security.',
    },
    // {
    //   name: 'Banking Systems Integration',
    //   description: 'Expertise in integrating core banking systems, digital platforms, and secure financial technologies for seamless banking operations.',
    // },
    {
        name: 'Cybersecurity Solutions',
        description: 'Offering comprehensive cybersecurity services, including network security, encryption, threat detection, and response strategies.',
    },
    {
        name: 'Managed IT Services and Support',
        description: 'Providing managed IT services, including remote monitoring, maintenance, and support for critical systems and networks.',
    },
    {
        name: 'CCTV and Surveillance Systems',
        description: 'Specialization in designing and installing advanced CCTV and surveillance systems for corporate, residential, and industrial security.',
    },
    {
        name: 'Electric Fencing and Access Control',
        description: 'Implementation of electric fencing and access control systems to enhance security for properties, businesses, and institutions.',
    },
    {
        name: 'Network Infrastructure and Data Center Setup',
        description: 'Installation and configuration of network infrastructure, including data center management, server setups, and connectivity solutions.',
    },
    {
        name: 'Cloud Computing and Virtualization',
        description: 'Experience in cloud infrastructure deployment, management, and virtualization for scalable and cost-effective IT solutions.',
    },
    {
        name: 'Internet of Things (IoT) Solutions',
        description: 'Implementing IoT devices and systems for smart homes, smart cities, and industrial automation.',
    },
    // {
    //   name: 'Smart City and Public Infrastructure ICT Solutions',
    //   description: 'Delivering ICT solutions for smart city initiatives, including traffic management, public safety, and energy management.',
    // },
    // {
    //   name: 'Artificial Intelligence (AI) and Machine Learning (ML)',
    //   description: 'Applying AI and ML to automate processes, analyze data, and enhance decision-making in various industries.',
    // },
    // {
    //   name: 'Robotic Process Automation (RPA)',
    //   description: 'Automating repetitive business tasks using RPA tools to improve efficiency and reduce human error.',
    // },
    // {
    //   name: 'Virtual Reality (VR) and Augmented Reality (AR)',
    //   description: 'Developing immersive VR and AR applications for education, entertainment, and enterprise use.',
    // },
    {
        name: 'CRM and Customer Support Systems',
        description: 'Integration and customization of CRM systems to improve customer support, engagement, and retention.',
    },
    {
        name: 'Digital Marketing and SEO Optimization',
        description: 'Digital marketing services, including SEO optimization, content creation, and social media management to grow your online presence.',
    },
    // {
    //   name: 'Telecom Billing Systems',
    //   description: 'Custom telecom billing system development and integration for seamless tracking and charging of services.',
    // },
    {
        name: 'Telemedicine and Healthcare Solutions',
        description: 'Delivering secure telemedicine platforms for remote healthcare consultations, monitoring, and patient management.',
    },
    {
        name: 'Supply Chain and Logistics Management',
        description: 'Optimizing supply chain and logistics processes through IT solutions for greater efficiency and cost-effectiveness.',
    },
    {
        name: 'School Management Solutions',
        description: 'Providing comprehensive school management systems that streamline administrative tasks such as student enrollment, fees and costs tracking, attendance tracking, grading, communication with parents, and resource management. These solutions are designed to enhance operational efficiency, improve student performance tracking, and enable better decision-making for educational institutions.',
    },
];




const Staff = () => {
    const dispatch = useDispatch();
    const sectionsRef = useRef([]);
    const visibilityRef = useRef([]); // New ref for tracking visibility

    const pictures = useSelector(state => state.launch.pictures);
    const [visibleSections, setVisibleSections] = useState({});

      useEffect(() => {
            dispatch(logPageVisit("Staff"));
        }, [dispatch]);
    
    

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setVisibleSections((prev) => ({
                            ...prev,
                            [entry.target.dataset.index]: true,
                        }));
                    }
                });
            },
            { threshold: 0.2 } // Adjust threshold to control when animation starts
        );

        visibilityRef.current.forEach((section) => {
            if (section) observer.observe(section);
        });

        return () => observer.disconnect();
    }, []);


    useEffect(() => {
        const options = {
            threshold: 0.1
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, options);

        // Capture current value of sectionsRef.current
        const currentSections = sectionsRef.current;

        currentSections.forEach(section => {
            observer.observe(section);
        });

        return () => {
            observer.disconnect(); // Disconnect the observer

            // Clean up by unobserving each section using the captured value
            currentSections.forEach(section => {
                if (section instanceof Element && observer) {
                    observer.unobserve(section);
                }
            });
        };
    }, []); // Empty dependency array to run effect only once


    useEffect(() => {
        window.scrollTo(0, 0);

        const textElement = document.querySelector(".matrix-effect");
        const text = "Meet the extraordinary minds driving our success—a diverse team united by a shared commitment to achieving excellence and delivering remarkable results";
        matrixTextEffect(textElement, text);

    }, []);

    return (
        <div className="staff-container">
            <Helmet>
                <title>Our Team - Tanzator</title>
                <meta name="description" content="Meet the talented team members behind Tanzator's success." />
                <meta name="keywords" content="Tanzator, team, about us, technology, Tanzania, website, software, develop, company, app, application" />
                <meta property="og:title" content="Our Team - Tanzator" />
                <meta property="og:description" content="Meet the talented team members behind Tanzator's success." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.tanzator.com/staff" />
                <meta property="og:image" content="https://www.tanzator.com/logo192.png" />
            </Helmet>
            <section className="all-hero">
                <div style={{ flex: 1, alignContent: 'end' }}>
                    <h1 className="staff-title">Our Team</h1>
                </div>
                <div style={{ flex: 1 }}>
                    <p className="matrix-effect">Meet the extraordinary minds driving our success—a diverse team united by a shared commitment to achieving excellence and delivering remarkable results</p>
                </div>
            </section>
            {/* <div className="team-list">
                {teamData.map(member => (
                    <Link to={`/staff/${member.id}`} key={member.id} className="team-member-link">
                        <div className="team-member-card">
                            <div className="image-container">
                                <ProgressiveImage
                                    //  src={member.imageUrl}
                                    src={pictures?.staff?.[member.id]}
                                    alt={member.name}
                                    className="team-member-image"
                                />
                            </div>
                            <div className="team-member-info">
                                <h2 className="team-member-name">{member.name}</h2>
                                <p className="team-member-position">{member.position}</p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div> */}

            <div className="animated-section" ref={el => sectionsRef.current[0] = el} >

                <TeamList
                    teamData={teamData}
                    pictures={pictures}
                    visibilityRef={visibilityRef}
                    visibleSections={visibleSections}
                />
            </div>

<div className="animated-section" ref={el => sectionsRef.current[1] = el}>


            <section className="image-sections-team">
                <Image
                    src={team}
                    alt="team"
                    preview={false}
                />
            </section>

            <section className="about-team" style={{ marginBottom: '10vh' }}>
                <div className="team-section-content">

                    <div className="team-section-introduction">
                        <h2>The Unseen Faces</h2>
                        <p>Our team is our greatest asset. Comprised of industry experts, creative thinkers, and dedicated professionals, we work collaboratively to achieve our goals and deliver exceptional results for our clients.</p>
                    </div>

                    <div className="services-distributed">
                        <Collapse style={{ flex: 1 }}>
                            {services.slice(0, (services?.length) / 2).map((service, index) => (
                                <Panel header={service.name + ' Experience'} key={index}>
                                    <p>{service.description}</p>
                                </Panel>
                            ))}
                        </Collapse>
                        <Collapse style={{ flex: 1 }}>
                            {services.slice((services?.length) / 2, (services?.length)).map((service, index) => (
                                <Panel header={service.name + ' Experience'} key={index}>
                                    <p>{service.description}</p>
                                </Panel>
                            ))}
                        </Collapse>
                    </div >

                </div>
            </section>

            </div>
            <section
                ref={el => sectionsRef.current[2] = el}
                className="contact-section animated-section">
                <h2>What we do best</h2>
                <Link to="/what-we-do"><FiArrowRight className="contact-arrow-icon" /></Link>
            </section>
        </div>
    );
}

export default Staff;
