// assets.js
export const logo = require('../../assets/logo.png');

export const light = require('../../assets/light.png');
export const watch = require('../../assets/watch.png');
export const clock = require('../../assets/clock.png');
export const binoculars = require('../../assets/binoculars.png');
export const team = require('../../assets/team.png');

export const rapost = require('../../assets/rapost.png');
export const tanzart = require('../../assets/tanzart.png');
export const swilla = require('../../assets/swilla.png');
export const yatch = require('../../assets/yatch.png');
export const ngnantranga = require('../../assets/ngnantranga2.png');
export const sanecka = require('../../assets/sanecka.jpeg');

export const microsoft = require('../../assets/microsoft.webp');
export const apple = require('../../assets/apple.png');
export const amazon = require('../../assets/amazon.png');
export const hp = require('../../assets/hp.png');
export const bluehost = require('../../assets/bluehost.png');
export const google = require('../../assets/google.png');
export const namecheap = require('../../assets/namecheap.png');


export const school = require('../../assets/school1.png');
export const fuel = require('../../assets/fuel1.png');

// export const logo = require('../../assets/logo.png');
