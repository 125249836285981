import React, { useState } from 'react';
import './ServiceItem.css'; // Ensure the correct path to your CSS file
import { Image } from 'antd';

const ServiceItem = ({ title, description, image, openModal }) => {
    const [isHovered, setIsHovered] = useState(Math.random() < 0.7 ? false : true);

    return (
        <div
            className={`tanzator-service-item-container ${isHovered ? 'hovered' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="tanzator-service-image">
                <Image
                    preview={false}
                    src={image}
                    alt={title}
                    loading="lazy"
                />
                {
                    !isHovered &&
                    <>
                        <div className="overlay"></div>
                        <h3 className="overlay-title">{title}</h3>
                    </>
                }            </div>
            <div className="tanzator-service-content">
                <h4>{title}</h4>
                <p>{description}</p>
                <div className="tanzator-service-actions">
                    <button
                        className="modal-button inquire"
                        onClick={() => openModal({ title }, 'Inquire')}
                    >
                        Inquire
                    </button>
                    <button
                        className="modal-button work-now"
                        onClick={() => openModal({ title }, 'WorkNow')}
                    >
                        Let's Work
                    </button>
                </div>
            </div>


        </div>
    );
};

export default ServiceItem;
