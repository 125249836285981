import React from 'react';
// import { Spin } from 'antd'; // Ant Design for spinner
import './Loading.css'; // Custom CSS for loading animation
import { logo } from '../assets/assets';

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-logo-container">
        <img src={logo} alt="Logo" className="loading-logo" />
        {/* <Spin size="large" /> */}
      </div>
      {/* <div className="loading-animation">
      </div> */}
      <div class="center-loading-dots">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>
    </div>
  );
};

export default Loading;
