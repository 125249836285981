import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './Projects.css';
import { FaGlobe, FaApple, FaGooglePlay } from 'react-icons/fa';
// import tanzart1 from '../../assets/pictures/tanzart1.jpg';
// import tanzart2 from '../../assets/pictures/tanzart2.jpg';
// import rapost1 from '../../assets/pictures/rapost1.jpg';
// import rapost2 from '../../assets/pictures/rapost2.jpg';
// import ngnantranga1 from '../../assets/pictures/ngnantranga1.png';
// import ngnantranga2 from '../../assets/pictures/ngnantranga2.png';
// import yatch1 from '../../assets/pictures/yatch1.png';
// import yatch2 from '../../assets/pictures/yatch2.png';
// import yatch3 from '../../assets/pictures/yatch3.png';
// import yatch4 from '../../assets/pictures/yatch4.png';
// import yatch5 from '../../assets/pictures/yatch5.png';
import { matrixTextEffect } from '../../utils';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import ProgressiveImage from '../common/ProgressiveImage';
import { notification } from 'antd';
import AppDownloadModal from './AppDownloadModal';
import { logPageVisit } from '../../actions/AnalyticsActions';

const projectsData = [
    {
        id: 2,
        name: "RAPOST - Rapid Online Store",
        type: "Mobile App",
        description: "In an age where e-commerce is rapidly evolving, Tanzator recognized the need for a platform that offers more than just a marketplace—it needed to be secure, seamless, and built on trust. Thus, RAPOST was envisioned as a revolutionary e-commerce solution where individuals could buy, sell, and exchange new, used, and refurbished products with confidence. The challenge was not just creating an online store but designing an ecosystem that guarantees quality, safety, and reliability.\n\nWith a team dedicated to innovation, Tanzator developed RAPOST as a multi-faceted platform, featuring user-friendly mobile applications, a dynamic landing page, and a powerful admin portal for streamlined management. The mobile app brings the marketplace to users’ fingertips, allowing seamless browsing, secure transactions, and real-time communication. The web landing page serves as an entry point, introducing RAPOST's unique value proposition and guiding users through its features. Behind the scenes, the admin portal ensures vendor verification, product authenticity, and transaction security.\n\nRAPOST redefines the online shopping experience by implementing a stringent vetting process for sellers, ensuring that every product meets stated specifications before it reaches the buyer. By incorporating safe transportation services, the platform guarantees that purchases arrive as expected, minimizing the risks associated with traditional online shopping. With Tanzator driving innovation, RAPOST continues to evolve, promising a future where trust and technology merge to create the ultimate e-commerce experience.",
        technologies: ["React", "Firebase"],
        status: "In Progress",
        startDate: "February 2024",
        endDate: "June 2024",
        // imageUrl: [rapost1, rapost2],
        previewLinks: {
            website: "https://www.rapost.store",
            appStore: "pending",
            playStore: "https://play.google.com/store/apps/details?id=store.rapost.app"
        },
        isLinkReady: {
            website: true,
            appStore: false,
            playStore: true
        },
        downloadAPK: {
            link: "https://expo.dev/artifacts/eas/wk9WwncTRTjFxjcjzNfPz3.apk",
            name: "Rapost.apk"
        }
    },
    {
        id: 1,
        name: "Tanzart",
        type: "Mobile App",
        description: "Tanzator embarked on a journey to revolutionize the world of digital art by crafting a platform where creativity knows no bounds. Tanzart was born out of the realization that artists across various disciplines—whether poets, musicians, storytellers, visual artists, or performers—lacked a dedicated space to share and develop their work seamlessly. The idea was ambitious: to create an all-in-one mobile application that not only enables artists to showcase their creations but also fosters a thriving community of like-minded individuals.\n\nFrom conceptualization to execution, Tanzator meticulously designed Tanzart, ensuring it offers an intuitive and immersive user experience. The mobile app, provides a smooth and dynamic interface where users can upload, edit, and share their work effortlessly. Complementing the mobile experience, a well-structured landing web page introduces users to the platform’s offerings, setting the stage for deeper engagement. Behind the scenes, an advanced admin portal made for content management, user interactions, and moderation, ensuring a safe and vibrant ecosystem.\n\nTanzart is more than just an application; it is a movement. It empowers artists to turn their passions into lifelong pursuits, bridging the gap between traditional and digital artistry. With a vision of continuous expansion, Tanzator remains committed to enhancing Tanzart with innovative features, making it the ultimate artistic hub of the future.",
        technologies: ["React Native", "Node.js", "MongoDB"],
        status: "Completed",
        startDate: "September 2023",
        endDate: "February 2025",
        // imageUrl: [tanzart1, tanzart2],
        previewLinks: {
            website: "https://www.tanzart.art",
            appStore: "",
            playStore: "pending"
        },
        isLinkReady: {
            website: true,
            appStore: false,
            playStore: false
        },
        downloadAPK: {
            link: "link",
            name: "Tanzart.apk"
        }
    },
    {
        id: 6,
        name: "Tanzator School Solutions",
        type: "Web & Mobile App",
        description: "Tanzator School Solutions is a transformative suite designed to modernize and streamline school management and student learning. Traditionally, many educational institutions faced challenges in handling academic records, financial management, administration, and student engagement due to reliance on manual processes. Tanzator School Solutions was created to bridge this gap by providing a fully integrated digital platform tailored to the needs of schools, teachers, parents, and students.\n\nThe system introduces a comprehensive range of features, including academic management, financial tracking, administrative automation, and interactive student learning tools. Schools can efficiently manage enrollment, attendance, grading, and communication while ensuring transparency in budgeting, fee collection, and resource allocation. Additionally, educators can enhance student engagement through digital learning resources, online assessments, and performance tracking.\n\nBeyond its current capabilities, Tanzator School Solutions is continuously evolving, with a vision to explore and integrate emerging technologies to further enhance the education sector. From AI-driven analytics to adaptive learning environments, Tanzator is committed to shaping the future of education through innovation, accessibility, and efficiency.",
        technologies: ["React", "React Native", "Node.js", "Firebase"],
        status: "Completed",
        startDate: "November 2024",
        endDate: "December 2024",
        previewLinks: {
            "website": "https://schoolsolutions.tanzator.com",
            "appStore": null,
            "playStore": null
        },
        isLinkReady: {
            website: true,
            adminPortal: false
        }
    }

];
const clientsProjectsData = [
    {
        id: 5,
        name: "Sanecka Brand",
        type: "Web Development",
        description: "Sanecka Brand approached us with a vision to revolutionize the way artists connect with opportunities. They wanted a modern recruitment platform that would enable artists to showcase their talents, get discovered, and access career-changing opportunities in a seamless and efficient way.\n\nTo bring this vision to life, we developed a cutting-edge landing website that serves as the gateway for artists, recruiters, and industry professionals. The website is designed with a sleek, modern interface, ensuring effortless navigation and a visually engaging experience. It highlights Sanecka Brand’s mission while providing essential tools for artists to showcase their talents.\n\nBeyond the public-facing platform, Sanecka Brand required a powerful suite of technology-driven features to support their operations. To meet this demand, we built a feature-rich admin portal that enables their team to efficiently manage artist applications, recruitment processes, and content updates. The admin panel includes real-time analytics, custom filtering tools, automated matching algorithms, and seamless communication tools to connect artists with recruiters.\n\nAdditionally, we integrated advanced technology features such as AI-powered talent recommendations, allowing recruiters to find the best matches based on skill sets, experience, and artistic style.  The system also includes artists' special profile that can be shared to promote the artists.\n\nThrough a blend of modern design, automation, and intelligent matchmaking, Sanecka Brand now has a fully scalable digital platform that empowers artists and streamlines recruitment, helping them build successful careers with the right opportunities at their fingertips.",
        technologies: ["React", "Next.js", "Firebase", "Node.js"],
        status: "Completed",
        startDate: "April 2024",
        endDate: "July 2024",
        previewLinks: {
            website: "https://www.saneckabrand.com",
            adminPortal: "private"
        },
        isLinkReady: {
            website: true,
            adminPortal: false
        }
    },
    {
        id: 3,
        name: "Yacht Cruise Connect",
        type: "Web App",
        description: "The vision behind Yacht Cruise Connect was clear—to create a seamless and luxurious booking platform for yacht enthusiasts and event planners alike. Before its inception, booking a yacht cruise often involved lengthy processes, multiple intermediaries, and uncertainty. Tanzator saw an opportunity to transform this experience into one of ease, efficiency, and sophistication.\n\nThrough meticulous planning and development, the Yacht Cruise Connect team collaborated with Tanzator to bring this vision to life. The result was a beautifully crafted landing page that serves as the gateway to an effortless booking experience. Designed with precision, the website captures the elegance and excitement of yacht adventures, allowing users to explore available cruises, select their preferred options, and make reservations with just a few clicks.\n\nBeyond the front-facing experience, an advanced admin portal was built to manage reservations, customer inquiries, and special event planning. With an intuitive dashboard, yacht service providers can oversee bookings, customize offerings, and provide real-time availability updates.\n\nYacht Cruise Connect is more than just a booking site; it is a bridge between adventure seekers and premium yacht experiences. Thanks to Tanzator’s innovative approach, the platform continues to redefine how people plan and enjoy their time on the water.",
        technologies: ["React", "Node.js", "PostgreSQL"],
        status: "Completed",
        startDate: "June 2024",
        endDate: "June 2024",
        // imageUrl: [yatch1, yatch2, yatch3, yatch4, yatch5],
        previewLinks: {
            website: "https://www.yatchcruiseconnect.com",
            appStore: null,
            playStore: null
        }
    },
    {
        id: 4,
        name: "Ngnantranga",
        type: "Web App",
        description: "In the heart of Anjouan, Comoros, the need for sustainable food production and security became more pressing than ever. Ngnantranga Food Hub Production, a pioneering enterprise, aimed to address these challenges by providing innovative solutions for local agriculture, food distribution, and sustainability. However, to amplify its impact and reach a wider audience, Ngnantranga needed a digital presence that reflected its mission and values.\n\nTanzator stepped in to transform this vision into reality. With a deep understanding of Ngnantranga’s goals, the team designed a compelling landing page that tells the story of resilience, sustainability, and progress. The website showcases the enterprise’s initiatives, educates visitors on food security, and provides a seamless experience for stakeholders to engage with Ngnantranga’s work.\n\nIn addition to the public-facing platform, an admin portal was developed to streamline internal operations. Through this secure portal, the Ngnantranga team can manage content, track engagement, and maintain an active connection with the community.\n\nBy blending technology with purpose, Tanzator has enabled Ngnantranga to extend its reach, drive its mission forward, and contribute to a future where food security is no longer a challenge but a reality for communities in need.",
        technologies: ["Angular", "Java", "MySQL"],
        status: "Completed",
        startDate: "June 2024",
        endDate: "June 2024",
        // imageUrl: [ngnantranga1, ngnantranga2],
        previewLinks: {
            website: "https://www.ngnantranga.com",
            appStore: null,
            playStore: null
        }
    }
];


const handleAppStoreClick = () => {
    notification.info({
        message: 'Coming Soon!',
        description: 'The app will be available in the App Store soon. Stay tuned!',
        placement: 'topRight',
        duration: 5,
    });
};
const handlePlayStoreClick = () => {
    notification.info({
        message: 'Coming Soon!',
        description: 'The app will be available in the Google Play Store soon. Stay tuned!',
        placement: 'topRight',
        duration: 5,
    });
};

const Projects = () => {

    const dispatch = useDispatch();
    const sectionsRef = useRef([]);

    const pictures = useSelector(state => state.launch.pictures);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [downloadAPK, setDownloadAPK] = useState({});
    const [showInstructions, setShowInstructions] = useState(false);

    useEffect(() => {
        dispatch(logPageVisit("Projects"));
    }, [dispatch]);




    useEffect(() => {
        const options = {
            threshold: 0.1
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, options);

        // Capture current value of sectionsRef.current
        const currentSections = sectionsRef.current;

        currentSections.forEach(section => {
            observer.observe(section);
        });

        return () => {
            observer.disconnect(); // Disconnect the observer

            // Clean up by unobserving each section using the captured value
            currentSections.forEach(section => {
                if (section instanceof Element && observer) {
                    observer.unobserve(section);
                }
            });
        };
    }, []); // Empty dependency array to run effect only once




    useEffect(() => {
        window.scrollTo(0, 0);

        const textElement = document.querySelector(".matrix-effect");
        const text = "Transformative projects that set new benchmarks. Discover how we've revolutionized industries and driven growth. Experience their successful impacts!";
        matrixTextEffect(textElement, text);

    }, []);

    const handleDownloadAPK = (downloadAPK) => {
        setDownloadAPK(downloadAPK);
        setShowInstructions(false);
        setIsModalVisible(true);
    };

    return (
        <div className="projects-container">
            <Helmet>
                <title>Our Projects - Tanzator</title>
                <meta name="description" content="Explore the impactful projects undertaken by Tanzator in the local and international markets." />
                <meta name="keywords" content="Tanzator, projects, innovative solutions, technology, Tanzania, website, software, develop, company, app, application" />
                <meta property="og:title" content="Our Projects - Tanzator" />
                <meta property="og:description" content="Explore the impactful projects undertaken by Tanzator in the local and international markets." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.tanzator.com/projects" />
                <meta property="og:image" content="https://www.tanzator.com/logo192.png" />
            </Helmet>
            <section className="all-hero">
                <div style={{ flex: 1, alignContent: 'end' }}>
                    <h1 className="projects-title">Our Projects</h1>
                </div>
                <div style={{ flex: 1 }}>
                    <p className="matrix-effect">Transformative projects that set new benchmarks. Discover how we've revolutionized industries and driven growth. Experience their successful impacts!</p>
                </div>
            </section>
            <div className="projects-list animated-section" ref={el => sectionsRef.current[0] = el}>
                {projectsData.map((project) => (
                    <div className="project-card" key={project.id}>
                        <Link to={`/projects/${project.id}`} className="project-link">
                            <ProgressiveImage
                                src={pictures?.projects?.[project.id]?.[0]}
                                alt={project.name}
                                className="project-image"
                            />
                            <div className="project-details">
                                <h2 className="project-name">{project.name}</h2>
                                {/* <p className="project-type">{project.type}</p> */}
                                <p className="project-description"><span className="project-description-clamped">{project.description}</span><span className="see-more-span">SEE MORE</span></p>

                            </div>
                        </Link>
                        <div className="project-actions">
                            {project.previewLinks.website && (
                                <a href={project.previewLinks.website} target="_blank" rel="noopener noreferrer" className="preview-link">
                                    <FaGlobe className="icon" /> <span className="main-text">Visit Website</span>
                                </a>
                            )}
                            {project.previewLinks.appStore && project.previewLinks.appStore === 'pending' && (
                                <div onClick={handleAppStoreClick} className="preview-link" title="appStore">
                                    <FaApple className="icon" />
                                    <span>
                                        <span className="small-text">Download on the</span>
                                        <span className="main-text">App Store</span>
                                    </span>
                                </div>
                            )}
                            {project.previewLinks.appStore && project.previewLinks.appStore !== 'pending' && (
                                <a href={project.previewLinks.appStore} target="_blank" rel="noopener noreferrer" className="preview-link" title="appStore">
                                    <FaApple className="icon" />
                                    <span>
                                        <span className="small-text">Download on the</span>
                                        <span className="main-text">App Store</span>
                                    </span>
                                </a>
                            )}

                            {project.previewLinks.playStore && project.previewLinks.playStore === 'pending' && (
                                <div onClick={handlePlayStoreClick} className="preview-link" title="playStore">
                                    <FaGooglePlay className="icon" />
                                    <span>
                                        <span className="small-text">Get it on</span>
                                        <span className="main-text">Google Play</span>
                                    </span>
                                </div>
                            )}

                            {project.previewLinks.playStore && !project.isLinkReady.playStore && project.previewLinks.playStore !== 'pending' && (
                                <div onClick={() => handleDownloadAPK(project?.downloadAPK)} className="preview-link" title="playStore">
                                    <FaGooglePlay className="icon" />
                                    <span>
                                        <span className="small-text">Get it on</span>
                                        <span className="main-text">Google Play</span>
                                    </span>
                                </div>
                            )}

                            {project.previewLinks.playStore && project.isLinkReady.playStore && project.previewLinks.playStore !== 'pending' && (
                                <a href={project.previewLinks.playStore} target="_blank" rel="noopener noreferrer" className="preview-link" title="playStore">
                                    <FaGooglePlay className="icon" />
                                    <span>
                                        <span className="small-text">Get it on</span>
                                        <span className="main-text">Google Play</span>
                                    </span>
                                </a>
                            )}
                        </div>

                    </div>
                ))}
            </div>


            <div className="animated-section" ref={el => sectionsRef.current[1] = el}>

                <h2 className="projects-list-title">What some wished to see</h2>
                <div className="projects-list">
                    {clientsProjectsData.map((project) => (
                        <div className="project-card" key={project.id}>
                            <Link to={`/projects/${project.id}`} className="project-link">
                                <ProgressiveImage
                                    src={pictures?.projects?.[project.id]?.[0]}
                                    alt={project.name}
                                    className="project-image"
                                />
                                <div className="project-details">
                                    <h2 className="project-name">{project.name}</h2>
                                    {/* <p className="project-type">{project.type}</p> */}
                                    <p className="project-description"><span className="project-description-clamped">{project.description}</span><span className="see-more-span">SEE MORE</span></p>

                                </div>
                            </Link>
                            <div className="project-actions">
                                {project.previewLinks.website && (
                                    <a href={project.previewLinks.website} target="_blank" rel="noopener noreferrer" className="preview-link">
                                        <FaGlobe className="icon" /> <span className="main-text">Visit Website</span>
                                    </a>
                                )}
                                {project.previewLinks.appStore && project.previewLinks.appStore === 'pending' && (
                                    <div onClick={handleAppStoreClick} className="preview-link" title="appStore">
                                        <FaApple className="icon" />
                                        <span>
                                            <span className="small-text">Download on the</span>
                                            <span className="main-text">App Store</span>
                                        </span>
                                    </div>
                                )}
                                {project.previewLinks.appStore && project.previewLinks.appStore !== 'pending' && (
                                    <a href={project.previewLinks.appStore} target="_blank" rel="noopener noreferrer" className="preview-link" title="appStore">
                                        <FaApple className="icon" />
                                        <span>
                                            <span className="small-text">Download on the</span>
                                            <span className="main-text">App Store</span>
                                        </span>
                                    </a>
                                )}

                                {project.previewLinks.playStore && project.previewLinks.playStore === 'pending' && (
                                    <div onClick={handlePlayStoreClick} className="preview-link" title="playStore">
                                        <FaGooglePlay className="icon" />
                                        <span>
                                            <span className="small-text">Get it on</span>
                                            <span className="main-text">Google Play</span>
                                        </span>
                                    </div>
                                )}

                                {project.previewLinks.playStore && project.previewLinks.playStore !== 'pending' && (
                                    <div onClick={() => handleDownloadAPK(project?.downloadAPK)} className="preview-link" title="playStore">
                                        <FaGooglePlay className="icon" />
                                        <span>
                                            <span className="small-text">Get it on</span>
                                            <span className="main-text">Google Play</span>
                                        </span>
                                    </div>
                                )}

                                {project.previewLinks.playStore && project.isLinkReady.playStore && project.previewLinks.playStore !== 'pending' && (
                                    <a href={project.previewLinks.playStore} target="_blank" rel="noopener noreferrer" className="preview-link" title="playStore">
                                        <FaGooglePlay className="icon" />
                                        <span>
                                            <span className="small-text">Get it on</span>
                                            <span className="main-text">Google Play</span>
                                        </span>
                                    </a>
                                )}
                            </div>

                        </div>
                    ))}
                </div>
            </div>
            <AppDownloadModal downloadAPK={downloadAPK} showInstructions={showInstructions} setShowInstructions={setShowInstructions} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} key={downloadAPK.link} />

        </div>
    );
}

export default Projects;
