import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './StaffProfile.css';
import { useDispatch, useSelector } from 'react-redux';
import ProgressiveImage from '../common/ProgressiveImage';
import { logPageVisit } from '../../actions/AnalyticsActions';
import {
    InstagramOutlined, FacebookOutlined, TwitterOutlined, YoutubeOutlined,
    AppleOutlined, SoundOutlined,
    BoldOutlined,
    SpotifyOutlined,
    MailOutlined,
    XOutlined,
    LinkedinOutlined,
    GlobalOutlined,
    PhoneOutlined
  } from '@ant-design/icons';
const teamData = [
    {
        id: 1,
        name: "Navigator Moses Timothy",
        position: "Founder, CEO & Software Engineer",
        bio: [
            {
                title: "ICT Enthusiast",
                content: "Navigator Moses Timothy is an ardent ICT enthusiast who possesses an insatiable curiosity for the digital realm. With a profound understanding of emerging technologies and their transformative potential, he immerses himself in the latest industry trends. His passion for innovation drives him to explore novel ideas and solutions that challenge the status quo, making him a vital asset in any technological endeavor."
            },
            {
                title: "Academic Achievements",
                content: "He earned his Bachelor's Degree in ICT from the prestigious University of Dar es Salaam, where he not only excelled academically but also engaged actively in various tech-related extracurricular activities. His time at the university was marked by his commitment to academic excellence and a relentless pursuit of knowledge, which laid a solid foundation for his future career in information and communication technology."
            },
            {
                title: "Project Experience",
                content: "With a rich tapestry of over 10 years of experience, Navigator has spearheaded an array of extensive projects across multiple ventures. His portfolio includes groundbreaking software development initiatives, intricate system integrations, and strategic ICT consulting services. Each project showcases his ability to navigate complex challenges, turning them into opportunities for innovation and growth, thereby significantly contributing to the success of the organizations he collaborates with."
            },
            {
                title: "Proven Track Record",
                content: "Navigator boasts a proven track record of driving innovation and achieving outstanding results in various ICT initiatives. His strategic foresight and analytical acumen have led to enhanced operational efficiency and considerable cost savings for clients. Colleagues and clients alike commend his unwavering commitment to delivering exceptional outcomes, which has positioned him as a trusted leader in the industry."
            },
            {
                title: "Driving Innovation",
                content: "At the heart of Navigator's professional ethos is a steadfast commitment to fostering creativity and innovation within his team. He believes that the best solutions often arise from collaborative brainstorming and open dialogue. By creating an environment that encourages out-of-the-box thinking, he empowers his colleagues to challenge conventional methodologies and embrace cutting-edge technologies, driving continuous improvement and adaptation."
            },
            {
                title: "Team Leadership",
                content: "Navigator excels in team leadership, having successfully managed diverse and dynamic groups throughout his career. His approachable demeanor and exceptional communication skills foster a culture of trust and collaboration. He leads by example, inspiring his team to remain motivated and focused, ensuring that project goals are met with both diligence and enthusiasm."
            },
            {
                title: "Client Relationship Management",
                content: "With an innate ability to build strong and lasting relationships with clients, Navigator prioritizes understanding their unique needs and aspirations. He approaches each client interaction with empathy and professionalism, ensuring that tailored solutions not only meet but exceed their expectations. This client-centric approach has resulted in high satisfaction and retention rates, positioning him as a key player in successful project outcomes."
            },
            {
                title: "Mentorship and Training",
                content: "Navigator’s passion for ICT extends beyond personal achievement; he is dedicated to nurturing the next generation of tech professionals. He actively mentors young talents, providing invaluable guidance and support to help them hone their skills and advance their careers. His commitment to knowledge-sharing and professional development exemplifies his belief in the power of mentorship to inspire and cultivate future leaders in the industry."
            },
            {
                title: "Community Engagement",
                content: "Deeply committed to making a difference, Navigator actively participates in community initiatives aimed at promoting digital literacy and access to technology for underserved populations. He believes that technology can empower individuals, enabling them to transform their lives and communities. Through various outreach programs, he advocates for equitable access to digital resources, fostering a more inclusive technological landscape."
            },
            {
                title: "Visionary Thinker",
                content: "Navigator is recognized as a visionary thinker, continually looking ahead to embrace emerging technologies such as artificial intelligence, blockchain, and the Internet of Things (IoT). His strategic vision positions his ventures at the forefront of innovation, ensuring that they not only adapt to changes in the ICT landscape but also lead in shaping the future of technology. He is dedicated to creating solutions that resonate with the demands of tomorrow’s world."
            }
        ],
        email: "ceo@tanzator.com",
        phone: "+255765009910",
        linkedin: "https://www.linkedin.com/in/tanzator"
    },
    {
        id: 2,
        name: "Swaghi Sonda Machafu",
        position: "General Manager",
        bio: [
            {
                title: "Great Managerial Skills",
                content: "Swaghi Sonda Machafu possesses exceptional managerial skills that serve as the backbone of his team's success. His keen ability to inspire and motivate individuals allows him to foster an environment of collaboration and high performance. With a focus on clear communication and strategic planning, he effectively aligns team goals with organizational objectives, ensuring that everyone is working towards a common vision."
            },
            {
                title: "Project Management",
                content: "He adeptly oversees multiple projects, balancing priorities with a laser focus on quality and efficiency. Swaghi's meticulous attention to detail and his proactive approach to risk management ensure that projects are completed on time and within budget. His expertise in project management methodologies allows him to adapt and thrive in dynamic environments, making him an invaluable asset to any organization."
            },
            {
                title: "Problem Solving",
                content: "Swaghi's remarkable problem-solving abilities are pivotal in ensuring smooth operations and successful outcomes. He approaches challenges with a logical mindset and a solutions-oriented attitude, quickly identifying root causes and implementing effective strategies. His analytical skills enable him to navigate complex situations, turning potential obstacles into opportunities for growth and improvement."
            },
            {
                title: "Team Building",
                content: "Swaghi excels in building strong, cohesive teams that work seamlessly together. He believes that a united team is key to achieving exceptional results, and he invests time in nurturing relationships among team members. By fostering open communication, encouraging diverse perspectives, and promoting a culture of mutual respect, he creates an inclusive environment where collaboration flourishes and innovation thrives."
            },
            {
                title: "Strategic Vision",
                content: "Swaghi embodies a strategic vision that enables him to anticipate future challenges and opportunities. His forward-thinking mindset allows him to set clear, achievable goals and craft actionable plans that guide his team toward success. By aligning individual strengths with organizational priorities, he ensures that the team remains agile and well-prepared to adapt to the ever-evolving business landscape."
            },
            {
                title: "Conflict Resolution",
                content: "A skilled mediator, Swaghi approaches conflicts with empathy and understanding. He values diverse opinions and actively listens to all parties involved, facilitating discussions that lead to constructive resolutions. His ability to address issues calmly and fairly not only strengthens team dynamics but also fosters a culture of openness where concerns can be expressed without fear."
            },
            {
                title: "Performance Optimization",
                content: "Swaghi is dedicated to performance optimization, continuously seeking ways to enhance productivity and efficiency within his team. He employs data-driven strategies and metrics to identify areas for improvement, ensuring that resources are utilized effectively. His commitment to ongoing training and development empowers team members to reach their full potential, resulting in sustained high performance."
            },
            {
                title: "Client Relationship Management",
                content: "With a knack for building strong relationships, Swaghi excels in managing client expectations and fostering long-term partnerships. He prioritizes understanding client needs and works diligently to deliver solutions that exceed their expectations. His proactive communication style and commitment to customer satisfaction have earned him a reputation as a trusted advisor in his field."
            },
            {
                title: "Adaptability and Resilience",
                content: "Swaghi's adaptability and resilience in the face of challenges are among his greatest strengths. He embraces change and encourages his team to view obstacles as opportunities for learning and growth. His positive attitude and unwavering determination inspire those around him to remain focused and motivated, even in the most demanding situations."
            }
        ],
        email: "manager@tanzator.com",
        phone: "+255685358479"
    }
];

const StaffProfile = () => {
    const dispatch = useDispatch();
    const { staffId } = useParams();
    const pictures = useSelector(state => state.launch.pictures);

    const member = teamData.find(member => member.id === parseInt(staffId));

    useEffect(() => {
        if (member) {
            dispatch(logPageVisit(`Staff Profile: ${member.name}`));
        }
    }, [dispatch]);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!member) return <div>Team member not found.</div>;

    return (
        <div className="staff-profile-container">
            <div className="staff-profile-card">
                <div className="staff-profile-image-container-container">
                    <div className="staff-profile-image-container">
                        <ProgressiveImage
                            src={pictures?.staffProfile?.[member.id]}
                            alt={member.name}
                            className="staff-profile-image"
                        />
                    </div>
                    <div className="staff-profile-basic-details">
                        <h2 className="staff-profile-name">{member.name}</h2>
                        <p className="staff-profile-position">{member.position}</p>
                    </div>
                </div>
                <div className="staff-profile-details">
                    {/* <h2 className="staff-profile-name">{member.name}</h2>
                    <p className="staff-profile-position">{member.position}</p> */}

                    <div className="staff-profile-bio">
                        {member.bio.map((section, index) => (
                            <div key={index} className="staff-bio-section">
                                <h3 className="bio-title">{section.title}</h3>
                                <p className="bio-content">{section.content}</p>
                            </div>
                        ))}
                    </div>

                    <div className="staff-profile-contact">
                        <p><a href={`mailto:${member.email}`}><MailOutlined style={{marginRight:5,}}/>{member.email}</a></p>
                        <p><a href={`tel:${member.phone}`}><PhoneOutlined style={{marginRight:5,}}/>{member.phone}</a></p>
                        {member.linkedin &&
                        <p>
                            <a href={member.linkedin} target="_blank" rel="noreferrer">
                            <LinkedinOutlined style={{marginRight:5,}}/>LinkedIn Profile
                            </a>
                        </p>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
}

export default StaffProfile;
