import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { db } from "../App";
import { BOOKING_FAILURE, BOOKING_REQUEST, BOOKING_SUCCESS, SAAS_REQUEST_FAILURE, SAAS_REQUEST_INITIATE, SAAS_REQUEST_SUCCESS } from "./Types";
import { message, notification } from "antd";


export const fetchPrivacyPolicy = async (productName, language) => {
    try {
        const docRef = doc(db, 'privacyPolicy', productName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            return data[language] || null; // Return the appropriate language content
        } else {
            // console.log("No such document!");
            return null;
        }
    } catch (error) {
        // console.error("Error fetching privacy policy: ", error);
        // throw new Error("Error fetching privacy policy");
    }
};



export const fetchTermsAndConditions = async (productName, language) => {
    try {

        const docRef = doc(db, 'terms', productName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            return data[language] || null; // Return the appropriate language content
        } else {
            // console.log("No matching terms and conditions found!");
            return null;
        }

    } catch (error) {
        // console.error('Error fetching terms and conditions:', error);
        // throw error;
    }
};



// Action Creator
export const addSaasBooking = (bookingData) => async (dispatch) => {
    dispatch({ type: BOOKING_REQUEST });

    try {
        // Add the document to the `saasBookings` collection
        const docRef = await addDoc(collection(db, "saasBookings"), bookingData);

        dispatch({
            type: BOOKING_SUCCESS,
            payload: { id: docRef.id, ...bookingData },
        });

        //   console.log("Document added with ID:", docRef.id);
        notification.success({ message: "Success", description: `Booking made successfully. We will get in touch soon!`});

    } catch (error) {
        //   console.error("Error adding document:", error);
        notification.error({ message: "Error", description: `Failed to place a booking!`});

        dispatch({
            type: BOOKING_FAILURE,
            payload: error.message,
        });
    }
};



// Action Creator
export const addSaasRequest = (requestData) => async (dispatch) => {
    dispatch({ type: SAAS_REQUEST_INITIATE });

    try {
        const docRef = await addDoc(collection(db, "saasRequests"), requestData);

        dispatch({
            type: SAAS_REQUEST_SUCCESS,
            payload: { id: docRef.id, ...requestData },
        });

        // console.log("Request submitted with ID:", docRef.id);
        notification.success({ message: "Success", description: `Your Saas request has been delivered successfully`});

    } catch (error) {
        // console.error("Error submitting request:", error);
        notification.error({ message: "Error", description: `Failed to submit request!`});

        dispatch({
            type: SAAS_REQUEST_FAILURE,
            payload: error.message,
        });
    }
};
