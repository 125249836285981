import React, { useState } from "react";
import { Modal, Form, Input, Button, Typography } from "antd";
import { useDispatch } from "react-redux";
import { createProcessService } from "../../actions/ServiceActions";

const { Title, Text } = Typography;

const ServiceModal = ({ modalOpen, closeModal, selectedService, selectedAction,buttonPosition }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const handleAction = () => {
        form
            .validateFields()
            .then((values) => {
                dispatch(createProcessService(selectedAction, selectedService, values?.name, values?.contact, values?.message || '',buttonPosition));
                closeModal();
            })
            .catch((errorInfo) => {
                console.error("Validation Failed:", errorInfo);
            });
    };

    return (
        <Modal
            title={<Title level={3}>{selectedService?.title || "Service Request"}</Title>}
            open={modalOpen}
            onCancel={closeModal}
            footer={null}
            centered
            destroyOnClose
        >
            <Text>Please provide your contact information:</Text>
            <Form form={form} layout="vertical">
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: "Name is required!" }]}
                >
                    <Input placeholder="Enter your name" />
                </Form.Item>

                <Form.Item
                    label="Contact Info"
                    name="contact"
                    rules={[{ required: true, message: "Email or phone number is required!" }]}
                >
                    <Input placeholder="Enter email or phone number" />
                </Form.Item>

                <Form.Item
                    label={selectedAction === "Inquire" ? "Inquiry information (optional)" : "Work scope (optional)"}
                    name="message"
                >
                    <Input.TextArea rows={3} placeholder="More details..." />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" block onClick={handleAction}>
                        {selectedAction === "Inquire" ? "Inquire" : "Let's Work"}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ServiceModal;
