import { message, notification } from "antd";
import { db } from "../App";

import {
  ADD_EMAIL,
  ADD_EMAIL_FAILURE,
  ADD_EMAIL_SUCCESS,
  CREATE_MESSAGE,
  CREATE_MESSAGE_FAILURE,
  CREATE_MESSAGE_SUCCESS,
  FETCH_ALL_MESSAGES_REQUEST,
  FETCH_ALL_MESSAGES_SUCCESS,
  FETCH_ALL_MESSAGES_FAILURE,
  DELETE_MESSAGE_REQUEST,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
  FETCH_ALL_NEWSLETTERS_REQUEST,
  FETCH_ALL_NEWSLETTERS_SUCCESS,
  FETCH_ALL_NEWSLETTERS_FAILURE,
  DELETE_NEWSLETTER_REQUEST,
  DELETE_NEWSLETTER_SUCCESS,
  DELETE_NEWSLETTER_FAILURE,
} from "./Types";

import { collection, addDoc, query, where, getDocs, setDoc, doc, deleteDoc } from 'firebase/firestore';


export const addEmailToNewsletter = (email) => {
  return async (dispatch) => {
    dispatch({ type: ADD_EMAIL });

    const newsletterRef = collection(db, 'newsletter');
    const newsletterDoc = doc(db, 'newsletter', email);

    // Check if email already exists
    const querySnapshot = await getDocs(query(newsletterRef, where('email', '==', email)));

    if (!querySnapshot.empty) {
      notification.error({ message: "Error", description: `Email (${email}) is already subscribed`});

      dispatch({
        type: ADD_EMAIL_FAILURE,
        payload: 'Email already subscribed.'
      });
      return;
    }

    // Add email to newsletter collection
    try {
      await setDoc(newsletterDoc, { email, time: Date.now(), source: "Tanzator" });
      notification.success({ message: "Success", description: `${email} has been successfully added to the newsletter!`});

      dispatch({ type: ADD_EMAIL_SUCCESS });

    } catch (error) {
      notification.error({ message: "Error", description: `There was an error adding ${email} to the newsletter mail-list!`});

      dispatch({
        type: ADD_EMAIL_FAILURE,
        payload: error.message
      });
    }

  };
};



// Action to fetch all newsletters
export const fetchAllNewsletters = () => {
  return async dispatch => {
    dispatch({ type: FETCH_ALL_NEWSLETTERS_REQUEST });

    try {
      const newslettersRef = collection(db, 'newsletter');
      const querySnapshot = await getDocs(newslettersRef);
      const newsletters = querySnapshot.docs.map(doc => doc.data().email);

      dispatch({
        type: FETCH_ALL_NEWSLETTERS_SUCCESS,
        payload: newsletters,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALL_NEWSLETTERS_FAILURE,
        payload: error.message,
      });
    }
  };
};

// Action to delete a newsletter by email
export const deleteNewsletter = (email) => {
  return async dispatch => {
    dispatch({ type: DELETE_NEWSLETTER_REQUEST });

    try {
      const newslettersRef = collection(db, 'newsletter');
      const q = query(newslettersRef, where('email', '==', email));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async doc => {
        await deleteDoc(doc.ref);
      });

      dispatch({ type: DELETE_NEWSLETTER_SUCCESS });
    } catch (error) {
      dispatch({
        type: DELETE_NEWSLETTER_FAILURE,
        payload: error.message,
      });
    }
  };
};




export const createMessage = (name, email, contactMessage) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_MESSAGE });

    const messagesRef = collection(db, 'siteMessages');

    // Add message to siteMessages collection
    try {
      await addDoc(messagesRef, {
        name,
        email,
        message: contactMessage,
        time: Date.now(),
        source: "Tanzator",
      });

      notification.success({ message: "Success", description: `Thank you.Your message has been successfully sent!`});

      dispatch({ type: CREATE_MESSAGE_SUCCESS });

    } catch (error) {
      notification.error({ message: "Error", description: `There was an error sending the message!`});

      dispatch({
        type: CREATE_MESSAGE_FAILURE,
        payload: error.message
      });
    }
  };
};



// Action to fetch all messages
export const fetchAllMessages = () => {
  return async dispatch => {
    dispatch({ type: FETCH_ALL_MESSAGES_REQUEST });

    try {
      const messagesRef = collection(db, 'siteMessages');
      const querySnapshot = await getDocs(messagesRef);
      const messages = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      dispatch({
        type: FETCH_ALL_MESSAGES_SUCCESS,
        payload: messages,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALL_MESSAGES_FAILURE,
        payload: error.message,
      });
    }
  };
};

// Action to delete a message by messageID
export const deleteMessage = (messageID) => {
  return async dispatch => {
    dispatch({ type: DELETE_MESSAGE_REQUEST });

    try {
      const messageRef = doc(db, 'siteMessages', messageID);
      await deleteDoc(messageRef);

      dispatch({ type: DELETE_MESSAGE_SUCCESS });
    } catch (error) {
      dispatch({
        type: DELETE_MESSAGE_FAILURE,
        payload: error.message,
      });
    }
  };
};
