import React, { useEffect, useRef, useState } from 'react';
import './Services.css';
import { FaLaptop, FaChartLine, FaUsers, FaCogs } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { createProcessService } from '../../actions/ServiceActions';
import { matrixTextEffect } from '../../utils';
import { Helmet } from 'react-helmet';
import ServiceItem from './ServiceItem';
import { Divider, Image } from 'antd';
import { fuel, school } from '../assets/assets';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import BookDemoModal from './BookDemoModal';
import SaasRequestModal from './SaasRequestModal';
import { useLocation } from 'react-router-dom';
import ServiceModal from '../common/ServiceModal';
import { logPageVisit } from '../../actions/AnalyticsActions';

const Services = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const sectionsRef = useRef([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);

    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [message, setMessage] = useState('');

    const [saasDisplayed, setSaasDisplayed] = useState(0);
    const [moduleSelected, setModuleSelected] = useState(null);

    const [error, setError] = useState(false);


    const [isSaasModalVisible, setIsSaasModalVisible] = useState(false);
    const [isBookModalVisible, setIsBookModalVisible] = useState(false);

    const pictures = useSelector(state => state.launch.pictures);

    // const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);

    const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
    const [progress, setProgress] = useState(0);


    // const saasPassed = location.state?.saasPassed;

    useEffect(() => {
        dispatch(logPageVisit("Services"));
    }, [dispatch]);



    useEffect(() => {
        const options = {
            threshold: [0.03, 0.1],  // Lower threshold for large elements, higher for others
            // rootMargin: "150px 0px"  // Triggers 150px before entering the viewport
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, options);

        // Ensure sectionsRef.current is available and is an array
        if (sectionsRef.current && Array.isArray(sectionsRef.current)) {
            sectionsRef.current.forEach(section => {
                if (section instanceof Element) {
                    observer.observe(section);
                }
            });
        }

        return () => {
            // Clean up observer
            if (sectionsRef.current && Array.isArray(sectionsRef.current)) {
                sectionsRef.current.forEach(section => {
                    if (section instanceof Element) {
                        observer.unobserve(section);
                    }
                });
            }
            observer.disconnect();
        };
    }, [sectionsRef]); // Dependency added if sectionsRef can change dynamically



    useEffect(() => {
        if (location.hash) {
            setTimeout(() => {
                const element = document.getElementById(location.hash.substring(1));
                // if (element) {
                //   element.scrollIntoView({ behavior: "smooth", block: "start" });
                // }
                if (element) {
                    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                    window.scrollTo({ top: elementPosition - 100, behavior: "smooth" });
                }

                // console.log(location.state)
                if (location.state?.saasPassed) {
                    setSaasDisplayed(location.state?.saasPassed)
                }

            }, 100); // Delay scrolling to ensure element exists
        }

    }, [location]);

    useEffect(() => {
        const totalDuration = 3000; // 3 seconds for each feature
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    setCurrentFeatureIndex((prevIndex) =>
                        (prevIndex + 1) % (moduleSelected?.features?.length || 1)
                    );
                    return 0; // Reset progress bar
                }
                return prev + (100 / (totalDuration / 100)); // Increment progress
            });
        }, 100); // Update progress every 100ms

        return () => clearInterval(interval); // Cleanup on unmount
    }, [moduleSelected]);

    useEffect(() => {
        window.scrollTo(0, 0);

        const textElement = document.querySelector(".matrix-effect");
        const text = "Innovative solutions tailored for your success. From strategic consulting to tech development, we're here to elevate your business. Book your spot, now!";
        matrixTextEffect(textElement, text);

    }, []);


    useEffect(() => {
        const handleMouseMove = (e) => {
            const rect = e.target.getBoundingClientRect();
            const x = e.clientX - rect.left; // x position within the element
            const y = e.clientY - rect.top;  // y position within the element

            e.target.style.setProperty('--mouse-x', `${x}px`);
            e.target.style.setProperty('--mouse-y', `${y}px`);
        };

        // const element1 = document.querySelector('.contact-section');
        const element2 = document.querySelector('.saas');

        // Function to add event listener and return a cleanup function
        const addMouseMoveListener = (element) => {
            if (element) {
                element.addEventListener('mousemove', handleMouseMove);
                return () => {
                    element.removeEventListener('mousemove', handleMouseMove);
                };
            }
            return () => { }; // Return a no-op function if the element doesn't exist
        };

        // const cleanup1 = addMouseMoveListener(element1);
        const cleanup2 = addMouseMoveListener(element2);

        // Cleanup listeners for both elements on component unmount
        return () => {
            // cleanup1();
            cleanup2();
        };
    }, []); // Empty dependency array to run this effect once



    const saas = [
        {
            title: "Tanzator School Solutions",
            link: "https://schoolsolutions.tanzator.com",
            featuredImage: school,
            modules: [
                {
                    name: "Academic",
                    description: "This module streamlines academic management, including student enrollment, class schedules, and exam results.",
                    features: [
                        "Student registration and enrollment",
                        "Class and timetable management",
                        "Exam creation and grading",
                        "Attendance tracking",
                        "Report card generation"
                    ],
                    image: "",
                    isFeatured: false,
                    isComplete: false
                },
                {
                    name: "Finance",
                    description: "Manage all financial operations, including fee collection, payroll, and budgeting, in a secure and efficient way.",
                    features: [
                        "Fee & Fine structure and invoicing",
                        "Payment tracking",
                        "Payroll management for staff",
                        "Budgeting and expense tracking",
                        "Financial reporting and analytics"
                    ],
                    image: "",
                    isFeatured: false,
                    isComplete: false
                },
                {
                    name: "Administration",
                    description: "Handle school operations such as staff management, resource allocation, and compliance reporting.",
                    features: [
                        "Staff management",
                        "Resource allocation and inventory tracking",
                        "Event and calendar management",
                        "School policy and compliance reporting",
                        "Parent-teacher communication portal"
                    ],
                    image: "",
                    isFeatured: false,
                    isComplete: false
                },
                {
                    name: "Full School System",
                    description: "An all-in-one integrated system that combines academic, finance, and administration modules for seamless school management.",
                    features: [
                        "Comprehensive academic management",
                        "Integrated finance and budgeting tools",
                        "Centralized administration operations",
                        "Cross-module data analytics and insights",
                        "Mobile-friendly and secure access"
                    ],
                    image: "",
                    isFeatured: true,
                    isComplete: true
                }
            ]
        },
        {
            title: "Tanzator Fuel System",
            // link: "https://fuel.tanzator.com",
            link: null,
            featuredImage: fuel,
            modules: [
                // {
                //     name: "Academic",
                //     description: "",
                // features:[],
                //     image: "",
                //     isFeatured: false,
                //     isComplete: false
                // },
                // {
                //     name: "Finance",
                //     description: "",
                // features:[],
                //     image: "",
                //     isFeatured: false,
                //     isComplete: false
                // },
                // {
                //     name: "Administration",
                //     description: "",
                // features:[],
                //     image: "",
                //     isFeatured: false,
                //     isComplete: false
                // },
                {
                    name: "Complete Fuel System",
                    description: "An all-in-one solution for managing fuel operations, including inventory, distribution, monitoring, and reporting.",
                    features: [
                        "Real-time fuel inventory tracking",
                        "Automated distribution scheduling",
                        "Integrated reporting and analytics",
                        "Leak detection and safety compliance",
                        "Fleet management integration"
                    ],
                    image: "",
                    isFeatured: true,
                    isComplete: true
                }

            ]
        },
        {
            title: "Custom Software",
            // link: "https://fuel.tanzator.com",
            link: null,
            featuredImage: fuel,
            modules: [
                // {
                //     name: "Academic",
                //     description: "",
                // features:[],
                //     image: "",
                //     isFeatured: false,
                //     isComplete: false
                // },
                // {
                //     name: "Finance",
                //     description: "",
                // features:[],
                //     image: "",
                //     isFeatured: false,
                //     isComplete: false
                // },
                // {
                //     name: "Administration",
                //     description: "",
                // features:[],
                //     image: "",
                //     isFeatured: false,
                //     isComplete: false
                // },
                {
                    name: "Complete Fuel System",
                    description: "An all-in-one solution for managing fuel operations, including inventory, distribution, monitoring, and reporting.",
                    features: [
                        "Real-time fuel inventory tracking",
                        "Automated distribution scheduling",
                        "Integrated reporting and analytics",
                        "Leak detection and safety compliance",
                        "Fleet management integration"
                    ],
                    image: "",
                    isFeatured: true,
                    isComplete: true
                }

            ]
        },

    ];


    const services = [
        {
            icon: <FaCogs />,
            image: pictures?.services?.businessIdea,
            title: "Business Idea Redesign With Tech",
            description: "Assist in developing and refining business ideas and strategies to achieve sustainable growth and success."
        },
        {
            icon: <FaUsers />,
            image: pictures?.services?.consulting,
            title: "Consulting Services",
            description: "Provide expert consultancy to optimize IT projects, strategies, and business processes."
        },
        {
            icon: <FaLaptop />,
            image: pictures?.services?.webDevelopment,
            title: "Web Development",
            description: "Custom web solutions tailored to your business needs. From simple websites to complex web applications."
        },
        {
            icon: <FaCogs />,
            image: pictures?.services?.customSoftware,
            title: "Custom Software Development",
            description: "Develop bespoke software solutions to address specific business requirements and challenges."
        },
        {
            icon: <FaLaptop />,
            image: pictures?.services?.mobileApp,
            title: "Mobile App Development",
            description: "Create innovative mobile applications for iOS and Android platforms, from concept to deployment."
        },
        {
            icon: <FaUsers />,
            image: pictures?.services?.uiUx,
            title: "UI/UX Design",
            description: "Craft intuitive and visually appealing user interfaces and experiences for digital products."
        },
        {
            icon: <FaChartLine />,
            image: pictures?.services?.qualityAssurance,
            title: "Quality Assurance and Testing",
            description: "Implement rigorous testing processes to ensure high-quality software products and solutions."
        },
        {
            icon: <FaCogs />,
            image: pictures?.services?.systemIntegration,
            title: "System Integration",
            description: "Integrate disparate systems and applications to streamline business processes and improve efficiency."
        },
        {
            icon: <FaLaptop />,
            image: pictures?.services?.seo,
            title: "SEO Optimization",
            description: "Optimize websites and digital content to improve search engine rankings and drive organic traffic."
        },
        {
            icon: <FaCogs />,
            image: pictures?.services?.database,
            title: "Database Design and Management",
            description: "Design and manage scalable and efficient databases to support business operations."
        },
        {
            icon: <FaCogs />,
            image: pictures?.services?.security,
            title: "Security Solutions",
            description: "Install and configure security systems including CCTV cameras, gate motors, and biometric access control."
        },
        {
            icon: <FaCogs />,
            image: pictures?.services?.cloud,
            title: "Cloud Services",
            description: "Implement scalable cloud solutions for storage, computing, and application deployment."
        },
        {
            icon: <FaCogs />,
            image: pictures?.services?.network,
            title: "Network Solutions",
            description: "Design, implement, and manage robust network infrastructure to ensure reliable connectivity."
        }
    ];

    const openModal = (service, action) => {
        setSelectedService(service);
        setSelectedAction(action);
        setModalOpen(true);
        setError(false);
    };

    const closeModal = () => {
        setSelectedService(null);
        setSelectedAction(null);
        setModalOpen(false);
        setError(false);
    };

    const handleSaasChange = (change) => {
        if (change === 'left') {
            if (saasDisplayed === 0) {
                setSaasDisplayed(saas?.length - 1)
            } else {
                setSaasDisplayed(saasDisplayed - 1)
            }

        } else if (change === "right") {
            if (saasDisplayed === (saas?.length - 1)) {
                setSaasDisplayed(0)
            } else {
                setSaasDisplayed(saasDisplayed + 1)
            }

        }

        handleClearModule();
    };

    const handleModuleClick = (module) => {

        setModuleSelected(module)

    };

    const handleClearModule = () => {

        setModuleSelected(null)

    };

    const handleAction = () => {
        if (name === '' || contact === '') {
            // Dispatch action for inquiry using ServiceActions
            setError(true);
            return;
        } else {
            dispatch(createProcessService(selectedAction, selectedService, name, contact, message));
            closeModal();
            setMessage('');
        }

    };

    return (
        <div className="services-container">
            <Helmet>
                <title>Our Services - Tanzator</title>
                <meta name="description" content="Discover the innovative solutions provided by Tanzator across various industries." />
                <meta name="keywords" content="Tanzator, services, innovative solutions, technology, Tanzania, website, software, develop, company, app, application" />
                <meta property="og:title" content="Our Services - Tanzator" />
                <meta property="og:description" content="Discover the innovative solutions provided by Tanzator across various industries." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.tanzator.com/what-we-do" />
                <meta property="og:image" content="https://www.tanzator.com/logo192.png" />
            </Helmet>
            <section className="all-hero">
                <div style={{ flex: 1, alignContent: 'end' }}>
                    <h1 className="services-title">Our Services</h1>
                </div>
                <div style={{ flex: 1 }}>
                    <p className="matrix-effect">Innovative solutions tailored for your success. From strategic consulting to tech development, we're here to elevate your business. Book your spot, now!</p>
                </div>
            </section>


            <div class="saas animated-section" id="saas" ref={el => sectionsRef.current[0] = el} style={{ marginBottom: '10vh' }}>
                <div class="top">
                    <h3>SOFTWARE AS A SERVICE (SAAS)</h3>
                    <Divider style={{ margin: '10px 2px' }} />

                    <div className="saas-controls">
                        <button className="saas-btn saas-btn-left" onClick={() => handleSaasChange('left')}>
                            &lt;
                        </button>
                        <h4 className="saas-heading">{saas[saasDisplayed]?.title}</h4>
                        <button className="saas-btn saas-btn-right" onClick={() => handleSaasChange('right')}>
                            &gt;
                        </button>
                    </div>
                    <Divider style={{ margin: '10px 2px' }} />

                </div>
                {
                    saas[saasDisplayed]?.title !== 'Custom Software' &&
                    <div class="middle">
                        <div class="middle-left"  >
                            {
                                moduleSelected &&
                                <Image src={saas[saasDisplayed]?.featuredImage} alt="SaaS Preview" />
                            }
                            {
                                !moduleSelected &&
                                <Image src={saas[saasDisplayed]?.featuredImage} alt="SaaS Preview" />
                            }
                        </div>
                        <div class="middle-right">

                            {
                                moduleSelected &&
                                <ul style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                                            <FiArrowLeft onClick={handleClearModule} style={{ fontSize: '20px', padding: '0 5px', cursor: 'pointer' }} /><h3>{moduleSelected?.name}</h3>
                                        </div>
                                        <Divider />
                                        <div>
                                            <p>{moduleSelected?.description}</p>
                                        </div>
                                        {/* Display Features */}
                                        <div style={{ margin: "1rem 0", fontSize: "0.875rem", fontWeight: "bold" }}>
                                            {moduleSelected?.features?.[currentFeatureIndex]}
                                        </div>

                                        {/* Progress Bar */}
                                        <div
                                            style={{
                                                height: "1px",
                                                width: "100%",
                                                background: "#lavender",
                                                borderRadius: "5px",
                                                overflow: "hidden",
                                                margin: "0.5rem 0",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: "100%",
                                                    width: `${progress}%`,
                                                    background: "#007bff",
                                                    transition: "width 0.1s linear",
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="tanzator-service-actions">
                                        <button
                                            className="modal-button inquire"
                                            onClick={() => openModal({ title: `${saas[saasDisplayed]?.title}: ${moduleSelected?.name}` }, 'Inquire')}
                                        >
                                            Inquire
                                        </button>
                                        <button
                                            className="modal-button work-now"
                                            onClick={() => openModal({ title: `${saas[saasDisplayed]?.title}: ${moduleSelected?.name}` }, 'WorkNow')}
                                        >
                                            Let's Work
                                        </button>
                                    </div>
                                </ul>
                            }

                            {
                                !moduleSelected &&
                                <ul>
                                    <div>
                                        <h3 class="saas-big-text">MODULES</h3>
                                    </div>
                                    <Divider />
                                    {saas[saasDisplayed]?.modules?.map((module) => (
                                        <li onClick={() => handleModuleClick(module)}><div style={{ display: 'flex', width: '100%', flexDirection: 'row', gap: '10px', justifyContent: 'space-between', cursor: 'pointer' }} className='module-name saas-small-text'><span>{module?.name}</span><FiArrowRight color='#007bff' /></div></li>
                                    ))}
                                </ul>
                            }
                        </div>
                    </div>
                }
                {
                    saas[saasDisplayed]?.title === 'Custom Software' &&
                    <div class="middle-empty">
                        <p class="middle-empty-paragraph">
                            Looking for a powerful software solution to optimize and grow your business, or company?
                        </p>
                    </div>
                }
                {
                    saas[saasDisplayed]?.title !== 'Custom Software' &&
                    <div class="bottom">
                        <button onClick={() => setIsBookModalVisible(true)} className="explore-button" style={{ margin: 0 }}>Book a demo </button>
                        {
                            saas[saasDisplayed]?.link &&
                            <a href={saas[saasDisplayed]?.link} target="_blank" rel="noreferrer" className="view-all-projects" style={{ textDecoration: 'none', margin: 0 }}>Visit Site</a>
                        }
                    </div>
                }
                {
                    saas[saasDisplayed]?.title === 'Custom Software' &&
                    <div class="bottom">
                        <button className="explore-button" onClick={() => setIsSaasModalVisible(true)} style={{ margin: 0 }}>Request a Software</button>
                    </div>
                }
            </div>



            <div className="services-list animated-section" ref={el => sectionsRef.current[1] = el} style={{ marginBottom: '10vh' }}>
                {/* {services.length !== 0 &&
                    services?.map((service, index) =>
                        <ServiceItem
                            title={service.title}
                            description={service.description}
                            image={service.image}
                            openModal={openModal}
                            key={index}
                        />
                    )
                } */}
                {
                    services?.flatMap((service, index) => {
                        // Insert a <div> at index 2 and 4, shifting the original items
                        if (index === 2 || index === 5) {
                            return ([
                                <div className=' empty-home-service tanzator-service-item-container'>
                                </div>
                                ,
                                <ServiceItem
                                    title={service.title}
                                    description={service.description}
                                    image={service.image}
                                    openModal={openModal}
                                    key={index}
                                />
                            ]);
                        }
                        if (index === 7) {
                            return ([
                                // <div className=' empty-home-service tanzator-service-item-container'>
                                // </div>
                                // ,
                                // <div className=' empty-home-service tanzator-service-item-container'>
                                // </div>
                                // ,
                                // <div className=' empty-home-service tanzator-service-item-container'>
                                // </div>
                                // ,
                                <ServiceItem
                                    title={service.title}
                                    description={service.description}
                                    image={service.image}
                                    openModal={openModal}
                                    key={index}
                                />
                            ]);
                        }
                        return (<ServiceItem
                            title={service.title}
                            description={service.description}
                            image={service.image}
                            openModal={openModal}
                            key={index}
                        />);
                    })
                }
            </div>

            {modalOpen && selectedService && (
                <ServiceModal
                    modalOpen={modalOpen}
                    closeModal={closeModal}
                    selectedAction={selectedAction}
                    selectedService={selectedService}
                    buttonPosition={"Services"}
                />
                // <div className="modal">
                //     <div className="modal-content">
                //         <span className="close" onClick={closeModal}>&times;</span>
                //         <h2>{selectedService.title}</h2>
                //         <p>Please provide your contact information:</p>
                //         {error && <p className="error">Name or Contact information missing</p>}
                //         <form className="contact-form">
                //             <label htmlFor="name">Name *</label>
                //             <input
                //                 type="text"
                //                 id="name"
                //                 name="name"
                //                 placeholder="Enter your name"
                //                 value={name}
                //                 onChange={(e) => setName(e.target.value)}
                //                 required
                //             />

                //             <label htmlFor="contact">Contact Info *</label>
                //             <input
                //                 type="text"
                //                 id="contact"
                //                 name="contact"
                //                 placeholder="Enter email or phone number"
                //                 value={contact}
                //                 onChange={(e) => setContact(e.target.value)}
                //                 required
                //             />

                //             <label htmlFor="message">{selectedAction === "Inquire" ? "Inquiry information (optional)" : "Work scope (optional)"}</label>
                //             <input
                //                 type="text"
                //                 id="message"
                //                 name="message"
                //                 placeholder="More details..."
                //                 value={message}
                //                 onChange={(e) => setMessage(e.target.value)}
                //             />

                //             <button type="button" className="submit-button" onClick={handleAction}>
                //                 {selectedAction === "Inquire" ? "Inquire" : "Let's Work"}
                //             </button>
                //         </form>
                //     </div>
                // </div>
            )}

            <SaasRequestModal
                visible={isSaasModalVisible}
                onClose={() => setIsSaasModalVisible(false)}
                buttonPosition={`Services`}
            />

            <BookDemoModal
                visible={isBookModalVisible}
                onClose={() => setIsBookModalVisible(false)}
                buttonPosition={`Services: ${saas[saasDisplayed]?.title}`}
            />
        </div>
    );
}

export default Services;
