
//............CONTACT ACTIONS
export const PROCESS_SERVICE = "process_service";
export const PROCESS_SERVICE_SUCCESS = "process_service_success";
export const PROCESS_SERVICE_FAILURE = "process_service_failure";

//............LAUNCH ACTIONS
export const FETCH_PICTURES = 'FETCH_PICTURES';
export const FETCH_PICTURES_SUCCESS = 'FETCH_PICTURES_SUCCESS';
export const FETCH_PICTURES_FAILURE = 'FETCH_PICTURES_FAILURE';



//............CONTACT ACTIONS
export const ADD_EMAIL = "add_email";
export const ADD_EMAIL_SUCCESS = "add_email_success";
export const ADD_EMAIL_FAILURE = "add_email_failure";

export const CREATE_MESSAGE = "create_message";
export const CREATE_MESSAGE_SUCCESS = "create_message_success";
export const CREATE_MESSAGE_FAILURE = "create_message_failure";

export const FETCH_ALL_NEWSLETTERS_REQUEST = 'FETCH_ALL_NEWSLETTERS_REQUEST';
export const FETCH_ALL_NEWSLETTERS_SUCCESS = 'FETCH_ALL_NEWSLETTERS_SUCCESS';
export const FETCH_ALL_NEWSLETTERS_FAILURE = 'FETCH_ALL_NEWSLETTERS_FAILURE';

export const DELETE_NEWSLETTER_REQUEST = 'DELETE_NEWSLETTER_REQUEST';
export const DELETE_NEWSLETTER_SUCCESS = 'DELETE_NEWSLETTER_SUCCESS';
export const DELETE_NEWSLETTER_FAILURE = 'DELETE_NEWSLETTER_FAILURE';

export const FETCH_ALL_MESSAGES_REQUEST = 'FETCH_ALL_MESSAGES_REQUEST';
export const FETCH_ALL_MESSAGES_SUCCESS = 'FETCH_ALL_MESSAGES_SUCCESS';
export const FETCH_ALL_MESSAGES_FAILURE = 'FETCH_ALL_MESSAGES_FAILURE';

export const DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILURE = 'DELETE_MESSAGE_FAILURE';




//.........AUTH ACTIONS
export const AUTH_FIELD_CHANGED = "auth_field_changed";
export const LOGIN_USER = "login_user";
export const LOGIN_USER_SUCCESSFUL = "login_user_successful";
export const LOGIN_USER_FAILED = "login_user_failed";
export const LOGOUT_USER = "logout_user";
export const LOGOUT_USER_SUCCESSFUL = "logout_user_successful";
export const LOGOUT_USER_FAILED = "logout_user_successful";
export const REGISTER_USER = "register_user";
export const REGISTER_USER_SUCCESSFUL = "register_user_successful";
export const REGISTER_USER_FAILED = "register_user_failed";

//.......MESSAGE ACTIONS
export const SET_MESSAGE = "set_message";
export const CLEAR_MESSAGE = "clear_message";



//.......SAAS ACTIONS
// export const SET_MESSAGE = "set_message";
// export const CLEAR_MESSAGE = "clear_message";

export const SAAS_REQUEST_INITIATE = "SAAS_REQUEST_INITIATE";
export const SAAS_REQUEST_SUCCESS = "SAAS_REQUEST_SUCCESS";
export const SAAS_REQUEST_FAILURE = "SAAS_REQUEST_FAILURE";

export const BOOKING_REQUEST = "BOOKING_REQUEST";
export const BOOKING_SUCCESS = "BOOKING_SUCCESS";
export const BOOKING_FAILURE = "BOOKING_FAILURE";


// Action Types
export const LOG_PAGE_VISIT = "LOG_PAGE_VISIT";
export const FETCH_ANALYTICS = "FETCH_ANALYTICS";

