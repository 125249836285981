import React, { useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { addSaasRequest } from "../../actions/SaasActions";

const SaasRequestModal = ({ visible, onClose,buttonPosition }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleFinish = (values) => {
    dispatch(addSaasRequest({ ...values, time: Date.now(), source: "Tanzator", buttonPosition }));
    onClose();
  };

  return (
    <Modal
      title="Request a software solution"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter your name" }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>
        <Form.Item
          label="Business/Company"
          name="business"
          rules={[{ required: true, message: "Please enter your business/company name" }]}
        >
          <Input placeholder="Enter your business or company name" />
        </Form.Item>
        <Form.Item
          label="Position"
          name="position"
          rules={[{ required: true, message: "Please enter your position" }]}
        >
          <Input placeholder="Enter your position" />
        </Form.Item>
        <Form.Item
          label="Contacts"
          name="contacts"
          rules={[{ required: true, message: "Please provide a contact number or email" }]}
        >
          <Input placeholder="Enter your contact information" />
        </Form.Item>
        <Form.Item
          label="Name of the SaaS (Software as a Service)"
          name="saasName"
          rules={[{ required: true, message: "Please specify the software solution" }]}
        >
          <Input placeholder="Enter the name of the software solution" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
        >
          <Input.TextArea placeholder="Enter any additional details" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Submit Request
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SaasRequestModal;
