import { FETCH_ANALYTICS, LOG_PAGE_VISIT } from "../actions/Types";

const initialState = {
  visits: [],
  currentVisits: [],
  entry:0
};

const AnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_PAGE_VISIT:
      return {
        ...state,
        // visits: [...state.visits, action.payload],
        currentVisits: [...state.currentVisits, action.payload.page],
        entry: state.entry + 1
      };
    
    case FETCH_ANALYTICS:
      return {
        ...state,
        visits: action.payload,
      };

    default:
      return state;
  }
};

export default AnalyticsReducer;
