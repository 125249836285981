import { collection, addDoc } from "firebase/firestore";
import { message, notification } from "antd";

import { PROCESS_SERVICE, PROCESS_SERVICE_FAILURE, PROCESS_SERVICE_SUCCESS } from "./Types";
import { db } from "../App";

export const createProcessService = (selectedAction, selectedService, name, contact, processMessage, buttonPosition) => {
  return async (dispatch) => {
    dispatch({ type: PROCESS_SERVICE });

    let collectionName;
    if (selectedAction === 'Inquire') {
      collectionName = 'Inquiries';
    } else if (selectedAction === 'WorkNow') {
      collectionName = 'Leads';
    } else {

      message.error(`Invalid action selected.`);

      dispatch({
        type: PROCESS_SERVICE_FAILURE,
        payload: 'Invalid action selected.'
      });

      return;
    }

    const actionRef = collection(db, collectionName);

    const data = {
      name,
      service: selectedService.title,
      contact,
      message: processMessage,
      time: Date.now(),
      source: "Tanzator",
      buttonPosition
    };

    try {
      await addDoc(actionRef, data);

      if (selectedAction === 'Inquire') {
        notification.success({ message: "Success", description: `${name}, inquiry for ${selectedService.title} made successfully. We will contact you soon!` });
      } else if (selectedAction === 'WorkNow') {
        notification.success({ message: "Success", description: `${name}, work request for ${selectedService.title} has been well received. Our team will contact you soon!` });
      }

      dispatch({ type: PROCESS_SERVICE_SUCCESS });

    } catch (error) {

      if (selectedAction === 'Inquire') {
        notification.error({ message: "Error", description: `${name}, inquiry for ${selectedService.title} failed. Please, resubmit!` });
      } else if (selectedAction === 'WorkNow') {
        notification.error({ message: "Error", description: `${name}, work request for ${selectedService.title} has not been sent. Please, submit again!` });
      }

      dispatch({
        type: PROCESS_SERVICE_FAILURE,
        payload: error.message
      });
    }
  };
};

