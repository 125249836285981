import React, { useEffect, useRef } from 'react';
import './About.css';
// import TeamPhoto from "../../assets/logo.png"; // Replace with actual path to team photo
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import { matrixTextEffect } from '../../utils';
import { Helmet } from 'react-helmet';
import { Collapse, Image } from 'antd';
import { logo, clock, light, binoculars, watch } from '../assets/assets';
import { ImTarget } from 'react-icons/im';
import { TiTick } from 'react-icons/ti';
import { useDispatch } from 'react-redux';
import { logPageVisit } from '../../actions/AnalyticsActions';

// import ProgressiveImage from '../common/ProgressiveImage';
// import { useSelector } from 'react-redux';
// import { matrixTextEffect } from '../../utils/matrixTextEffect'; // Import the utility function

const About = () => {
    // const pictures = useSelector(state => state.launch.pictures);
const dispatch =useDispatch();
    const sectionsRef = useRef([]);

    
  useEffect(() => {
    dispatch(logPageVisit("About"));
  }, [dispatch]);


    useEffect(() => {
        window.scrollTo(0, 0);

        const textElement = document.querySelector(".matrix-effect");
        const text = "Your trusted partner in innovative solutions. We stands for integrity, innovation, and a future-driven approach. Let’s build success together!";
        matrixTextEffect(textElement, text);

    }, []);

    useEffect(() => {
        const options = {
            threshold: 0.1
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, options);

        // Capture current value of sectionsRef.current
        const currentSections = sectionsRef.current;

        currentSections.forEach(section => {
            observer.observe(section);
        });

        return () => {
            observer.disconnect(); // Disconnect the observer

            // Clean up by unobserving each section using the captured value
            currentSections.forEach(section => {
                if (section instanceof Element && observer) {
                    observer.unobserve(section);
                }
            });
        };
    }, []); // Empty dependency array to run effect only once


    useEffect(() => {
        const handleMouseMove = (e) => {
            const rect = e.target.getBoundingClientRect();
            const x = e.clientX - rect.left; // x position within the element
            const y = e.clientY - rect.top;  // y position within the element

            e.target.style.setProperty('--mouse-x', `${x}px`);
            e.target.style.setProperty('--mouse-y', `${y}px`);
        };

        const element = document.querySelector('.about-team');
        if (element) {
            element.addEventListener('mousemove', handleMouseMove);

            // Cleanup listener on component unmount
            return () => {
                element.removeEventListener('mousemove', handleMouseMove);
            };
        }
    }, []); // Empty dependency array to run this effect once




    return (
        <div className="about-container">
            <Helmet>
                <title>About Us - Tanzator</title>
                <meta name="description" content="Learn more about Tanzator, our mission, vision, history, and our team." />
                <meta name="keywords" content="Tanzator, about us, mission, vision, team, history, website, software, develop, company, app, application" />
                <meta property="og:title" content="About Us - Tanzator" />
                <meta property="og:description" content="Learn more about Tanzator, our mission, vision, history, and our team." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.tanzator.com/about" />
                <meta property="og:image" content="https://www.tanzator.com/logo192.png" />
            </Helmet>

            <section className="all-hero">
                <div style={{ flex: 1, alignContent: 'end' }}>
                    <h1>About Tanzator</h1>
                </div>
                <div style={{ flex: 1 }}>
                    <p className="matrix-effect">Your trusted partner in innovative solutions. We stands for integrity, innovation, and a future-driven approach. Let’s build success together!</p>
                </div>
            </section>

            <section >
                <div ref={el => sectionsRef.current[0] = el} className="about-mission-top animated-section">
                    <section className="about-mission about-sections">
                        <div className="section-content">
                            <h2>Our Mission</h2>
                            <p>To provide innovative and sustainable solutions that empower communities and drive progress in various industries. We are committed to excellence, integrity, and social responsibility in all our endeavors.</p>
                        </div>
                    </section>

                    <section className=" image-sections-mission">
                        {/* <div className="section-content">
                        <h2><span className="about-icon vision-icon" role="img" aria-label="Globe">🌍</span>ur Vision</h2>
                        <p>To be a global leader in providing transformative solutions that positively impact the world, fostering a culture of innovation, collaboration, and continuous improvement.</p>
                    </div> */}
                        <Image
                            src={light}
                            // src={pictures?.company ? pictures?.company?.logo : logo}
                            alt="mission"
                            // className='mission-img'
                            preview={false}
                        />
                    </section>
                </div>
                <div ref={el => sectionsRef.current[1] = el} className="about-vision-top animated-section">
                    <section className=" image-sections-vision">
                        {/* <div className="section-content">
                        <h2><span className="about-icon mission-icon" role="img" aria-label="Lightbulb">💡</span>ur Mission</h2>
                        <p>To provide innovative and sustainable solutions that empower communities and drive progress in various industries. We are committed to excellence, integrity, and social responsibility in all our endeavors.</p>
                    </div> */}
                        <Image
                            src={binoculars}
                            // src={pictures?.company ? pictures?.company?.logo : logo}
                            alt="binoculars"
                            // style={{translate:'rotateY(180deg) !important'}}
                            className='binoculars-img'
                            preview={false}
                        />
                    </section>

                    <section className="about-vision about-sections">
                        <div className="section-content">
                            <h2>Our Vision</h2>
                            <p>To be a global leader in providing transformative solutions that positively impact the world, fostering a culture of innovation, collaboration, and continuous improvement.</p>
                        </div>
                    </section>
                </div>

                <div ref={el => sectionsRef.current[2] = el} className="about-history-top animated-section">

                    <section className="about-history about-sections">
                        <div className="section-content">
                            <h2>Our History</h2>
                            <p>Founded in 2023, Tanzator has grown from a small startup to a leading provider of innovative solutions across various industries. Our journey began with a simple idea: to leverage technology and creativity to solve real-world problems. Over the years, we have expanded our expertise, built a diverse team of professionals, and established a strong presence in the market.</p>
                            {/* <p>Our milestones include:</p>
                    <ul>
                        <li>2023: Tanzator was founded with a vision to innovate.</li>
                        <li>2024: Launched our first major project, revolutionizing the local market (Rapost)</li>
                        <li>2024: Expanded our services internationally i.e. Built a MVP; Comoros based Web App (Ngnantranga)</li>
                    </ul>
                    <p>What we envision:</p>
                    <ul>
                        <li>2024: Bringing out multi-sector systems involving in agricultural activities</li>
                        <li>2024: Launching world-wide impactful Apps and Systems like Tanzart</li>
                        <li>2025: Strengthening roots in major ICT services provision</li>
                    </ul> */}
                        </div>
                    </section>
                    <section className=" image-sections-history">
                        {/* <div className="section-content">
                        <h2><span className="about-icon mission-icon" role="img" aria-label="Lightbulb">💡</span>ur Mission</h2>
                        <p>To provide innovative and sustainable solutions that empower communities and drive progress in various industries. We are committed to excellence, integrity, and social responsibility in all our endeavors.</p>
                    </div> */}
                        <Image
                            src={watch}
                            // src={pictures?.company ? pictures?.company?.logo : logo}
                            alt="binoculars"
                            // className='mission-img'
                            preview={false}
                        />
                    </section>

                </div>
                <div ref={el => sectionsRef.current[3] = el} className="about-milestones-top animated-section">

                    <section className="about-milestones about-sections">
                        <div className="section-content">
                            <p>Our milestones include:</p>
                            <ul>
                                <li><TiTick color='#007bff' />2023: Tanzator was founded with a vision to innovate.</li>
                                <li><TiTick color='#007bff' />2024: Engagement in futuristic brand creations</li>
                                <li><TiTick color='#007bff' />2024: Expanded our services internationally in Web Application development</li>
                                <li><TiTick color='#007bff' />2024: Launched RAPOST - Rapid Online Store, aimed to revolutionalize the local market</li>
                                <li><TiTick color='#007bff' />2024: Began helping businesses thrive through Saas, Software as a Service</li>
                            </ul>
                            <p>What we envision:</p>
                            <ul>
                                <li><ImTarget />2025: Launching Tanzart</li>
                                <li><ImTarget />2025: Expanding Saas, Software as a Service, into agriculture, offices, retails,...</li>
                                <li><ImTarget />2025: Improve Custom Software delivery; shorter timeframes and more stunning standards</li>
                                <li><ImTarget />2025: Strengthening roots in major ICT services provision</li>
                                <li><ImTarget />2025: Continuous innovations</li>
                            </ul>
                        </div>
                    </section>

                </div>

            </section>

            <section
                ref={el => sectionsRef.current[4] = el}
                className="contact-section animated-section"
            >
                <h2>What we do best</h2>
                {/* <div className="contact-info">
                                <p>Dar es Salaam, Tanzania</p>
                                <p>Email: <a href="mailto:info@tanzator.com">info@tanzator.com</a></p>
                                <p>Phone: <a href="tel:+255765009910">+255 765 009 910</a></p>
                            </div> */}
                {/* <div className="contact-icons">
                                <a href="mailto:info@tanzator.com"><i className="far fa-envelope"></i></a>
                                <a href="tel:+255765009910"><i className="fas fa-phone-alt"></i></a>
                            </div> */}
                <Link to="/what-we-do"><FiArrowRight className="contact-arrow-icon" /></Link>
            </section>
        </div>
    );
}

export default About;
