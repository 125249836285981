import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import './PrivacyPolicy.css'; // Import the styles
import { fetchPrivacyPolicy } from '../../actions/SaasActions';
import { logPageVisit } from '../../actions/AnalyticsActions';
import { useDispatch } from 'react-redux';

const PrivacyPolicy = () => {
    const dispatch = useDispatch();
    const { productName, language } = useParams();
    const [content, setContent] = useState('');

    useEffect(() => {
        if (productName) {
            dispatch(logPageVisit(`Privacy Policy: ${productName}`));

        }
    }, [dispatch, productName]);


    useEffect(() => {
        const loadData = async () => {
            const data = await fetchPrivacyPolicy(productName, language);
            if (data) {
                setContent(data);
            }
        };
        loadData();
    }, [productName, language]);

    return (
        <div className="privacy-policy-container">
            {parse(content)}
        </div>
    );
};

export default PrivacyPolicy;
