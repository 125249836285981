import React, { useEffect, useRef, useState } from 'react';
import './Contact.css';
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { addEmailToNewsletter, createMessage } from '../../actions/ContactActions';
// import ProgressiveImage from '../common/ProgressiveImage';
import logo from '../../assets/logo.png'; // Replace with actual image path
import { Button, Image, Input, Form } from 'antd';
import { Helmet } from 'react-helmet';
import { logPageVisit } from '../../actions/AnalyticsActions';
// import { Form } from 'react-router-dom';

const Contact = () => {
    const sectionsRef = useRef([]);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();

    const dispatch = useDispatch();

    const pictures = useSelector(state => state.launch.pictures);

    const [email, setEmail] = useState('');
    const [newsletterEmail, setNewsletterEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [newsletterError, setNewsletterError] = useState(null);

    const [subscribed, setSubscribed] = useState(false);

 useEffect(() => {
    dispatch(logPageVisit("Contact"));
  }, [dispatch]);


    useEffect(() => {
        const options = {
            threshold: 0.1
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, options);

        // Capture current value of sectionsRef.current
        const currentSections = sectionsRef.current;

        currentSections.forEach(section => {
            observer.observe(section);
        });

        return () => {
            observer.disconnect(); // Disconnect the observer

            // Clean up by unobserving each section using the captured value
            currentSections.forEach(section => {
                if (section instanceof Element && observer) {
                    observer.unobserve(section);
                }
            });
        };
    }, []); // Empty dependency array to run effect only once


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        const handleMouseMove = (e) => {
            const rect = e.target.getBoundingClientRect();
            const x = e.clientX - rect.left; // x position within the element
            const y = e.clientY - rect.top;  // y position within the element

            e.target.style.setProperty('--mouse-x', `${x}px`);
            e.target.style.setProperty('--mouse-y', `${y}px`);
        };

        const element1 = document.querySelector('.contact-contact-form');
        const element2 = document.querySelector('.newsletter');

        // Function to add event listener and return a cleanup function
        const addMouseMoveListener = (element) => {
            if (element) {
                element.addEventListener('mousemove', handleMouseMove);
                return () => {
                    element.removeEventListener('mousemove', handleMouseMove);
                };
            }
            return () => { }; // Return a no-op function if the element doesn't exist
        };

        const cleanup1 = addMouseMoveListener(element1);
        const cleanup2 = addMouseMoveListener(element2);

        // Cleanup listeners for both elements on component unmount
        return () => {
            cleanup1();
            cleanup2();
        };
    }, []); // Empty dependency array to run this effect once




    const handleSubmit = (values) => {
        // e.preventDefault();


        // console.log("Form Submitted:", values);
        // message.success("Message sent successfully!");
        // return;
        setNewsletterError(null);

        // Simple form validation
        if (!values.email || !values.name || !values.message) {
            setError('Please fill in all fields.');
            return;
        } else {
            setError(null);
        }

        // Dispatch action to create message
        dispatch(createMessage(values.name, values.email, values.message));
        // Clear form fields after submission
        // setName('');
        // setEmail('');
        // setMessage('');
        form.resetFields();
    };

    const handleSubscription = (values) => {
        // console.log("Form Submitted:", values);
        // message.success("Message sent successfully!");
        // return;

        setError(null);

        // Validate email
        if (!values.newsletterEmail) {
            setNewsletterError('Please enter your email.');
            return;
        } else {
            setNewsletterError(null);
        }

        // Dispatch action to add email to newsletter
        dispatch(addEmailToNewsletter(values.newsletterEmail));
        // Clear email field after submission
        // setNewsletterEmail('');
        form2.resetFields();
        setSubscribed(true);
    };

    return (
        <div className="contact-container">

            <Helmet>
                <title>Contact Us - Tanzator Investments</title>
                <meta name="description" content="Get in touch with Tanzator Investments for inquiries, support, or collaboration. Contact us via email, phone, or social media, and visit our office." />
                <meta name="keywords" content="Tanzator, contact us, newsletter, email, phone, social media, office location, maps, support, inquiries" />
                <meta property="og:title" content="Contact Us - Tanzator Investments" />
                <meta property="og:description" content="Reach out to Tanzator Investments for ICT services, support, or collaboration. Visit our office, contact us via phone, email, or social media." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.tanzator.com/contact" />
                <meta property="og:image" content="https://www.tanzator.com/logo192.png" />
            </Helmet>

            <div className="contact-form-section animated-section" ref={el => sectionsRef.current[0] = el}>
                <div className="contact-contact-form">
                    <h2 className="form-title">Send Us a Message</h2>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleSubmit}
                        className="contact-form"
                        style={{ width: '100%' }}
                    >
                        <div className="form-top-group" style={{ display: "flex", gap: "20px" }}>
                            <div style={{ flex: 1 }}>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[{ required: true, message: "Please enter your name" }]}
                                >
                                    <Input placeholder="Enter your name" />
                                </Form.Item>

                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        { required: true, message: "Please enter your email" },
                                        { type: "email", message: "Please enter a valid email" },
                                    ]}
                                >
                                    <Input placeholder="Enter your email" />
                                </Form.Item>
                            </div>

                            <div style={{ flex: 1 }}>
                                <Form.Item
                                    label="Message"
                                    name="message"
                                    rules={[{ required: true, message: "Please enter your message" }]}
                                >
                                    <Input.TextArea rows={5} placeholder="Enter your message" />
                                </Form.Item>
                            </div>
                        </div>

                        {error && <p className="error">{error}</p>}

                        {/* <Form.Item> */}
                        <div style={{ alignSelf: 'start' }}>

                            <Button type="primary" htmlType="submit" className="submit-button">
                                Send Message
                            </Button>
                        </div>
                        {/* </Form.Item> */}
                    </Form>
                </div>
            </div>
            <div className="office-and-social animated-section" ref={el => sectionsRef.current[1] = el}>
                <div className="office-details">
                    <h2 className="office-title">Our Office</h2>
                    <div className="office-info">
                        <p><FaMapMarkerAlt /><a href="https://www.google.com/maps/place/Dar+es+Salaam,+Tanzania/@-6.7692225,39.0894728,10z/" target="_blank" rel="noreferrer">Dar es Salaam, Tanzania</a></p>
                        {/* <p><FaMapMarkerAlt /><a href="https://www.google.com/maps/place/Dar+es+Salaam,+Tanzania/@-6.7692225,39.0894728,10z/" target="_blank" rel="noopener noreferrer">Dar es Salaam, Tanzania</a></p> */}
                        <p><FaEnvelope /> <a href="mailto:info@tanzator.com">info@tanzator.com</a></p>
                        <p><FaPhone /> <a href="tel:+255765009910">+255 765 009 910</a></p>
                        <p><FaWhatsapp /><a href="https://wa.me/message/D5EFQCRI52JJF1" target="_blank" rel="noreferrer" aria-label="WhatsApp">WhatsApp</a></p>
                    </div>
                </div>
                <div className="social-media">
                    <h3 className="social-media-title">Follow Us</h3>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/tanzatorinvestments" aria-label="Facebook" className="social-icon" target='_blank' rel='noreferrer'><FaFacebookF /> Tanzator Investments</a>
                        <a href="https://www.twitter.com/tanzator" aria-label="Twitter" className="social-icon" target='_blank' rel='noreferrer'><FaTwitter /> Tanzator</a>
                        <a href="https://www.linkedin.com/company/tanzator" aria-label="LinkedIn" className="social-icon" target='_blank' rel='noreferrer'><FaLinkedinIn /> Tanzator</a>
                        <a href="https://www.instagram.com/tanzatorinvestments" aria-label="Instagram" className="social-icon" target='_blank' rel='noreferrer'><FaInstagram /> @tanzatorinvestments</a>
                    </div>
                </div>
            </div>

            <div className="contact-form-section animated-section" ref={el => sectionsRef.current[2] = el}>

                <div className="newsletter">
                    <h2 className="newsletter-title">Subscribe to Our Newsletter</h2>
                    {!subscribed ? (
                        <Form form={form2} layout="vertical" onFinish={handleSubscription} className="newsletter-form">
                            <Form.Item
                                label="Email"
                                name="newsletterEmail"
                                rules={[
                                    { required: true, message: "Please enter your email!" },
                                    { type: "email", message: "Enter a valid email!" },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            {newsletterError && <p className="error">{newsletterError}</p>}

                            <div style={{ alignSelf: 'start' }}>

                                <Button type="primary" htmlType="submit" className="submit-button">
                                    Subscribe
                                </Button>
                            </div>

                        </Form>
                    ) : (
                        <p className="subscribed-message">Thank you for subscribing!</p>
                    )}
                </div>
            </div>


            <div className="map-office animated-section" ref={el => sectionsRef.current[3] = el}>
                <div className="map">
                    <iframe
                        // width="100%"
                        // height="100%"
                        title="Tanzator Location"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253568.33077612525!2d39.089472767127056!3d-6.769222473736852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4bae169bd6f1%3A0x940f6b26a086a1dd!2sDar%20es%20Salaam%2C%20Tanzania!5e0!3m2!1sen!2sus!4v1718935083371!5m2!1sen!2sus"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        frameBorder="0"
                        aria-hidden="false"
                        tabIndex="0"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
                <div className="office-picture">
                    <Image
                        src={pictures?.office?.officePic ? pictures?.office?.officePic : logo}
                        alt="Office"
                        className="office-image"
                        preview={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default Contact;
