import React, { Suspense, useLayoutEffect, useState } from 'react';
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import _ from 'lodash';
// import { Spinner } from "./components/common";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { fetchPictures } from './actions/LaunchActions';

//file imports
import './App.css';
import './components/home/Home.css';
import './components/projects/Projects';


import Topbars from './components/navigation/Topbars';
import Footer from './components/footer/Footer';
import Loading from './components/loading/Loading';
import ErrorScreen from './components/loading/ErrorScreen';
import { logo } from './components/assets/assets';
import Home from './components/home/Home';
import Services from './components/services/Services';
import Projects from './components/projects/Projects';
import Staff from './components/staff/Staff';
import StaffProfile from './components/staff/StaffProfile';
import ProjectDetails from './components/projects/ProjectDetails';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Terms from './components/terms/Terms';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
import DeleteAccount from './components/deleteAccount/DeleteAccount';
import NotFound from './components/notFound/NotFound';
// const Home = lazy(() => import('./components/home/Home'));



const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);
logEvent(analytics, 'page_view');

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

const App = () => {
  const dispatch = useDispatch();

  const [isSlow] = useState(false);
  // const [isSlow, setIsSlow] = useState(false);

  // const [user, setUser] = useState(null);
  // const myProfile = useSelector(state => state.auth.myProfile);
  const { loading, pictures, error } = useSelector(state => state.launch);

  // useEffect(() => {
  //   const unregisterAuthObserver = onAuthStateChanged(auth, user => {
  //     setUser(user ? true : false);
  //   });
  //   return () => unregisterAuthObserver(); // cleanup subscription on unmount

  // }, [dispatch]);


  // Effect to track slow network and set timeout
  // useEffect(() => {
  //   // Set timeout for detecting slow network (e.g., 3 seconds)
  //   const timer = setTimeout(() => {
  //     setIsSlow(true);
  //   }, 3000); // Timeout after 3 seconds

  //   // Clear the timeout if the component loads before the timeout
  //   return () => clearTimeout(timer);
  // }, []);

  useLayoutEffect(() => {
    dispatch(fetchPictures());
  }, [dispatch]);

  if (loading) return <Loading />;

  if (error) return <ErrorScreen />;


  const SlowLoadingComponent = () => (
    <Topbars>
      <div className="slow-loading-message">
        <p>It's taking longer than expected to load the content. Please check your internet connection, try again or wait a bit longer!</p>
      </div>
      <Footer />
    </Topbars>
  );
  const CenterPlaceholderComponent = () => (
    <Topbars>
      <div className='center-placeholder'>
        <img src={logo} alt='logo' />
        <div className="center-loading-animation">
        </div>
        {/* <div class="center-loading-dots">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div> */}
      </div>
      <Footer />
    </Topbars>
  );


  const renderAccess = () => {

    return (
      <div className="mainContainer">
        <Suspense fallback={
          isSlow ? (
            <SlowLoadingComponent />
          ) : (
            <CenterPlaceholderComponent />
          )
        }>
          <Routes>
            <Route element={<Topbars />}>
              <Route path='/' element={<Home />} />
              <Route path='/what-we-do' element={<Services />} />
              <Route path='/about' element={<About />} />
              <Route path='/projects' element={<Projects />} />
              <Route path='/projects/:projectId' element={<ProjectDetails />} />
              <Route path='/staff' element={<Staff />} />
              <Route path='/staff/:staffId' element={<StaffProfile />} />
              <Route path='/contact' element={<Contact />} />
              {/* <Route path="/picture/:name" element={<Picture />} /> */}
              <Route path="/terms-and-conditions/:productName/:language" element={<Terms />} />
              <Route path="/privacy-policy/:productName/:language" element={<PrivacyPolicy />} />
              <Route path="/delete-account/:productName" element={<DeleteAccount />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Suspense>
      </div>
    );
  };

  return (
    <BrowserRouter>
      {
        !loading
        &&
        pictures
        &&
        renderAccess()
      }
    </BrowserRouter>
  );
};

export default App;
