import React, { useEffect, useState } from 'react';
import './Terms.css';
import { fetchTermsAndConditions } from '../../actions/SaasActions';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logPageVisit } from '../../actions/AnalyticsActions';

const Terms = () => {
  const dispatch = useDispatch();
  const { productName, language } = useParams();
  const [termsContent, setTermsContent] = useState('');


  useEffect(() => {
    if (productName) {
      dispatch(logPageVisit(`Terms: ${productName}`));
    }
  }, [dispatch, productName]);


  useEffect(() => {
    const getTermsAndConditions = async () => {
      try {
        const content = await fetchTermsAndConditions(productName, language);
        setTermsContent(content);
      } catch (error) {
        // console.error('Error fetching terms and conditions:', error);
      }
    };

    getTermsAndConditions();
  }, [productName, language]);

  return (
    <div className="terms-container">
      <div
        className="terms-content"
        dangerouslySetInnerHTML={{ __html: termsContent }}
      />
    </div>
  );
};

export default Terms;
